import { FC, ChangeEvent, useState, useEffect } from 'react';
import { format } from 'date-fns';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  TextField,
  Grid,
  Button,

} from '@mui/material';
// import swal from "sweetalert";
import Label from 'src/components/Label';
// import { AddUser, RecentOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import BulkActions from './BulkActions';
import { BaseAPI } from 'src/providers/base-api';
import { Padding } from '@mui/icons-material';
// import validateEmail from '../profile/utils';
import { useParams } from 'react-router';
import { AddUser } from 'src/models/CommonInterface';
import { RecentOrderStatus } from 'src/models/crypto_order';

interface RecentOrdersTableProps {
  className?: string;
  cryptoOrders: AddUser[];
}

interface Filters {
  status?: RecentOrderStatus;
}

interface SignUpFieldErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  userRole?: string;
  password?: string;
  mobile?: string;
  city?: string;
}

const getStatusLabel = (RecentOrderStatus: RecentOrderStatus): JSX.Element => {
  const map = {
    failed: {
      text: 'Failed',
      color: 'error'
    },
    completed: {
      text: 'Completed',
      color: 'success'
    },
    pending: {
      text: 'Pending',
      color: 'warning'
    }
  };

  const { text, color }: any = map[RecentOrderStatus];

  return <Label color={color}>{text}</Label>;
};

const applyFilters = (
  cryptoOrders: AddUser[],
  filters: Filters
): AddUser[] => {
  return cryptoOrders.filter((cryptoOrder) => {
    let matches = true;

   

    return matches;
  });
};

const applyPagination = (
  cryptoOrders: AddUser[],
  page: number,
  limit: number
): AddUser[] => {
  return cryptoOrders.slice(page * limit, page * limit + limit);
};

const AddUsersField: FC<RecentOrdersTableProps> = ({ cryptoOrders }) => {
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [validated, setValidated] = useState(false);
  const [images, setImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [roles , assignbysetvalue] = useState();
  const params = useParams();
  const userId = params.id ? +params.id : undefined;
  




  const [filters, setFilters] = useState<Filters>({
    status: null
  });
  const [form, setForm] = useState<AddUser>({
    email: "",
    firstName: "",
    lastName: "",
    city: "",
    userRole: "",
    password: "",
    mobile: "",
  });

  const [errors, setErrors] = useState<AddUser>({
    firstName: "",
    lastName: "",
    email: "",
    userRole: "",
    password: "",
    city: "",
    mobile: "",
  });

  const statusOptions = [
    {
      id: 'all',
      name: 'All'
    },
    {
      id: 'completed',
      name: 'Completed'
    },
    {
      id: 'pending',
      name: 'Pending'
    },
    {
      id: 'failed',
      name: 'Failed'
    }
  ];

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const  Roles = [
    {
      value: 'admin',
      label: 'admin'
    },
    {
      value: 'user',
      label: 'user'
    }
  ];

  const UserRoles = (event) => {
    assignbysetvalue(event.target.value);
  };
  

  const handleSelectOneCryptoOrder = (
    event: ChangeEvent<HTMLInputElement>,
    cryptoOrderId: string
  ): void => {
    if (!selectedCryptoOrders.includes(cryptoOrderId)) {
      setSelectedCryptoOrders((prevSelected) => [
        ...prevSelected,
        cryptoOrderId
      ]);
    } else {
      setSelectedCryptoOrders((prevSelected) =>
        prevSelected.filter((id) => id !== cryptoOrderId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const validateForm = () => {
    const { firstName, lastName, email, userRole, password, mobile, city } =
      form;
    const newErrors: SignUpFieldErrors = {};
    if (!email || email === "") newErrors.email = "This field is required";
    if (!firstName || firstName === "")
      newErrors.firstName = "This field is required";
    if (!lastName || lastName === "")
      newErrors.lastName = "This field is required";
    if (!userRole || userRole === "")
      newErrors.userRole = "This field is required";
    if (!password || password === "")
      newErrors.password = "This field is required";
    if (!mobile || mobile === "") newErrors.mobile = "This field is required";
    if (!city || city === "") newErrors.city = "This field is required";
    return newErrors;
  };

  const handleChange = (fieldName, value) => {
    setForm((prevState) => ({ ...prevState, [fieldName]: value }));
    if (!!errors[fieldName])
      setErrors({
        ...errors,
        [fieldName]: null,
      });
  };
  const handleUpdate = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const newErrors = validateForm();
    setValidated(true);
    setErrors(newErrors);
    if (!form?.firstName) {
      return;
    }
    if (!form?.lastName) {
      return;
    }
    // if (!validateEmail(form?.email)) {
    //   return;
    // }
    if (!form?.userRole) {
      return;
    }
    if (!form?.password) {
      return;
    }
    if (!form?.mobile) {
      return;
    }
    if (!form?.city) {
      return;
    }
    try {
      const formdata = new FormData();
      const payload = {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        userRole: form.userRole,
        password: form.password,
        mobile: form.mobile,
        city: form.city,
      };
      if (images?.length) {
        formdata.append("file", images?.[0]?.file);
      }
      formdata.append("firstName", form.firstName);
      formdata.append("lastName", form.lastName);
      formdata.append("email", form.email);
      formdata.append("userRole", form.userRole);
      formdata.append("password", form.password);
      formdata.append("mobile", form.mobile);
      formdata.append("city", form.city);

      await BaseAPI.updateUserByID(payload, userId, (result: any) => {
        setErrorMessage(result?.response?.data?.errors?.username);
        if (result.status === 200 || result.status === 201) {
          // swal({
          //   text: "User Updated Successfully!",
          //   icon: "success",
          //   timer: 1000,
          // });
          // navigate("/users");
        } else {
          // swal({
          //   text: "Check your Username!",
          //   icon: "warning",
          //   timer: 1000,
          // });
        }
      });
    } catch (error) {
      setErrorMessage(error?.response?.data?.errors?.username);
    }
    setValidated(true);
  };



  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const newErrors = validateForm();
    
    setValidated(true);
    setErrors(newErrors);
    if (!form?.firstName) {
      console.log("!");
      
      
      return;
    }
    if (!form?.lastName) {
      return;
    }
    // if (!validateEmail(form?.email)) {
    //   return;
    // }
    if (!form?.userRole) {
      return;
    }
    if (!form?.password) {
      return;
    }
    if (!form?.mobile) {
      return;
    }
    if (!form?.city) {
      return;
    }
    try {
      const formdata = new FormData();
      if (images?.length) {
        formdata.append("file", images?.[0]?.file);
      }
      formdata.append("firstName", form.firstName);
      formdata.append("lastName", form.lastName);
      formdata.append("email", form.email);
      formdata.append("userRole", form.userRole);
      formdata.append("password", form.password);
      formdata.append("mobile", form.mobile);
      formdata.append("city", form.city);
      await BaseAPI.addUserList(form, (result: any) => {
        setErrorMessage(result?.response?.data?.errors?.username);
        if (result.status === 200 || result.status === 201) {
          // swal({
          //   text: "You have successfully add user...!",
          //   icon: "success",
          //   timer: 1000,
          // });
          // navigate("/users");
        } else {
          // swal({
          //   text: "Check your Username!",
          //   icon: "warning",
          //   timer: 1000,
          // });
        }
      });
    } catch (error) {
      console.log("error");
      
      setErrorMessage(error?.response?.data?.errors?.username);
    }
    setValidated(true);
  };
  useEffect(() => {
    if (userId) {
      BaseAPI.getUserByID(userId, (result) => {
        if (result?.status == 200) {
          const data = result?.data;
          setForm({
            email: data?.email,
            firstName: data?.firstName,
            lastName: data?.lastName,
            city: data?.city,
            userRole: data?.userRole,
            password: data?.password,
            mobile: data?.mobile,
          });
        }
      });
    }
  }, [userId]);
  const filteredCryptoOrders = applyFilters(cryptoOrders, filters);
  const paginatedCryptoOrders = applyPagination(
    filteredCryptoOrders,
    page,
    limit
  );
  const selectedSomeCryptoOrders =
    selectedCryptoOrders.length > 0 &&
    selectedCryptoOrders.length < cryptoOrders.length;
  const selectedAllCryptoOrders =
    selectedCryptoOrders.length === cryptoOrders.length;
  const theme = useTheme();

  return (
    <Card>
      {!selectedBulkActions && (
        <CardHeader
          action={
            <Box width={150}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={filters.status || 'all'}
                  onChange={handleStatusChange}
                  label="Status"
                  autoWidth
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          }
          title={userId ? "Edit Users" : "New Users"}
        />
      )}
      <Divider />
      <Box
        component="form"
        onSubmit={userId ? handleUpdate : handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <div className="ticket-fields">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                required
                id="standard-required"
                label="First Name"
                variant="standard"
                value={form.firstName}
                name="firstName"
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                id="standard-required"
                label="Last Name"
                variant="standard"
                value={form.lastName}
                name="lastName"
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                id="standard-required"
                label="Email"
                variant="standard"
                // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                          
                          value={form.email}
                          // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                          name="email"
                          onChange={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                id="standard-required"
                label="MobileNO"
                variant="standard"
                value={form.mobile}
                name="mobile"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
            <TextField
              id="standard-select-currency"
              select
              label="Roles"
              value={form.userRole}
              name="userRole"
              variant="standard"
              required
              onChange={(e: any) =>
                handleChange(e.target.name, e.target.value)
              }
            >
              {Roles.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                id="standard-required"
                label="City"
                variant="standard"
                value={form.city}
                          name="city"
                          onChange={(e: any) =>
                            handleChange(e.target.name, e.target.value)
                          }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                id="standard-required"
                label="Password"
                variant="standard"
                type="password"
                placeholder="Password *"
                value={form.password}
                name="password"
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }
              />
            </Grid>
          </Grid>
        </div>
        <div className="Save-button">
          <Button
            type="submit"
            sx={{ mt: { xs: 2, md: 0 } }}
            variant="contained"
          >
            Save
          </Button>
        </div>
          </Box>
            
    </Card>
  );
};

  

 
// useEffect(() => {
//   getticket('a');
// }, []);

AddUsersField.propTypes = {
  cryptoOrders: PropTypes.array.isRequired
};

AddUsersField.defaultProps = {
  cryptoOrders: []
};


export default AddUsersField;

