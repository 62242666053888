

import { useContext } from 'react';

import { ListSubheader, alpha, Box, List, styled, Button, ListItem, Popover, Collapse, Icon, ListItemButton, ListItemIcon, ListItemText, BottomNavigation, BottomNavigationAction, Grid } from '@mui/material';
import { NavLink as RouterLink, To } from 'react-router-dom';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PeopleIcon from '@mui/icons-material/People';
import BadgeIcon from '@mui/icons-material/Badge';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PixIcon from '@mui/icons-material/Pix';
import AddTaskIcon from '@mui/icons-material/AddTask';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';



import React, { useState, useEffect } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
.MuiList-root {
padding: ${theme.spacing(1)};

& > .MuiList-root {
padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
}
}

.MuiListSubheader-root {
text-transform: uppercase;
font-weight: bold;
font-size: ${theme.typography.pxToRem(12)};
color: ${theme.colors.alpha.trueWhite[50]};
padding: ${theme.spacing(0, 2.5)};
line-height: 1.4;
}
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
.MuiList-root {

.MuiListItem-root {
padding: 1px 0;

.MuiBadge-root {
position: absolute;
right: ${theme.spacing(3.2)};

.MuiBadge-standard {
background: ${theme.colors.primary.main};
font-size: ${theme.typography.pxToRem(10)};
font-weight: bold;
text-transform: uppercase;
color: ${theme.palette.primary.contrastText};
}
}
.MuiButton-root {
display: flex;
color: ${theme.colors.alpha.trueWhite[70]};
background-color: transparent;
width: 100%;
justify-content: flex-start;
padding: ${theme.spacing(1.2, 3)};

.MuiButton-startIcon,
.MuiButton-endIcon {
transition: ${theme.transitions.create(['color'])};

.MuiSvgIcon-root {
font-size: inherit;
transition: none;
}
}

.MuiButton-startIcon {
color: ${theme.colors.alpha.trueWhite[30]};
font-size: ${theme.typography.pxToRem(20)};
margin-right: ${theme.spacing(1)};
}
.MuiButton-endIcon {
color: ${theme.colors.alpha.trueWhite[50]};
margin-left: auto;
opacity: .8;
font-size: ${theme.typography.pxToRem(20)};
}

&.active,
&:hover {
background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
color: ${theme.colors.alpha.trueWhite[100]};

.MuiButton-startIcon,
.MuiButton-endIcon {
color: ${theme.colors.alpha.trueWhite[100]};
}
}
}

&.Mui-children {
flex-direction: column;

.MuiBadge-root {
position: absolute;
right: ${theme.spacing(7)};
}
}

.MuiCollapse-root {
width: 100%;

.MuiList-root {
padding: ${theme.spacing(1, 0)};
}

.MuiListItem-root {
padding: 1px 0;

.MuiButton-root {
padding: ${theme.spacing(0.8, 3)};

.MuiBadge-root {
right: ${theme.spacing(3.2)};
}

&:before {
content: ' ';
background: ${theme.colors.alpha.trueWhite[100]};
opacity: 0;
transition: ${theme.transitions.create(['transform', 'opacity'])};
width: 6px;
height: 6px;
transform: scale(0);
transform-origin: center;
border-radius: 20px;
margin-right: ${theme.spacing(1.8)};
}

&.active,
&:hover {

&:before {
transform: scale(1);
opacity: 1;
}
}
}
}
}
}
}
`
);

function SidebarMenu() {
  const [openRoles, setOpenRoles] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [category, setCategory] = useState([]);
  const [activeItem, setActiveItem] = useState(null);

  const handleClick = (type) => {
    setOpenRoles((prevOpenRoles) => ({
      ...prevOpenRoles,
      [type]: !prevOpenRoles[type],
    }));

    if (!openRoles[type]) {
      setActiveItem(type);

    } else {
      setActiveItem(null); 
    }
    setActiveSubMenu(null);

  };

  const getCategory = () => {
    let getUser = JSON.parse(localStorage.getItem('operations'));
  }

  useEffect(() => {
    getCategory();
    setCategory(JSON.parse(localStorage.getItem('operations')));

  }, []);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const handleSubMenuClick = (subMenuItem) => {
    setActiveSubMenu(subMenuItem.id);
    setActiveItem(null); 
  };

  return (
    <MenuWrapper>
      <List
        component="div"
        subheader={<></>}>
        <SubMenuWrapper>
          {category?.map((role) => (

            <div key={role.id}>
              
              <ListItemButton
                onClick={() => handleClick(role.title)}
                disableRipple={true}
                component={role?.path ? RouterLink : 'div'}
                to={role.path || undefined}
                sx={{
                  backgroundColor: activeItem === role.title ? '#8a917f8c' : 'transparent',
                  color: activeItem === role.title ? 'white' : 'inherit',
                  '&:hover': {
                    backgroundColor: activeItem === role.title ? '#8a917f8c' : 'transparent',
                    color: activeItem === role.title ? 'white' : 'inherit',
                  },
                }}
                >

                {role.icon === 'TableChartTwoToneIcon' && <TableChartTwoToneIcon style={{ marginRight: '10px' }} />}
                {role.icon === 'FingerprintIcon' && <FingerprintIcon style={{ marginRight: '10px' }} />}
                {role.icon === 'BadgeIcon' && <BadgeIcon style={{ marginRight: '10px' }} />}
                {role.icon === 'AssignmentIndIcon' && <AssignmentIndIcon style={{ marginRight: '10px' }} />}
                {role.icon === 'EngineeringIcon' && <EngineeringIcon style={{ marginRight: '10px' }} />}
                {role.icon === 'PeopleIcon' && <PeopleIcon style={{ marginRight: '10px' }} />}
                {role.icon === 'PixIcon' && <PixIcon style={{ marginRight: '10px' }} />}
                {role.icon === 'AddTaskIcon' && <AddTaskIcon style={{ marginRight: '10px' }} />}
                {role.icon === 'EnergySavingsLeafIcon' && <EnergySavingsLeafIcon style={{ marginRight: '10px' }} />}
                {role.icon === 'SettingsOutlinedIcon' && <SettingsOutlinedIcon style={{ marginRight: '10px' }} />}

                
                <ListItemText primary={role.title} />
                {role.subMenu.length > 0 ?
                  openRoles[role.title] ? <ExpandLessIcon /> : <ExpandMoreIcon />
                  : ""
                }
              </ListItemButton>
              {role.subMenu.length > 0 && (
      <Collapse in={openRoles[role.title]} timeout="auto" unmountOnExit>
       <List component="div" disablePadding>
  {role.subMenu.map((subMenuItem) => (
    <ListItemButton
      key={subMenuItem.id}
      component={RouterLink}
      to={subMenuItem.routePath}
      sx={{
        "&:hover": {
          backgroundColor: "grey",
          color: "white",
        },
        backgroundColor: activeSubMenu === subMenuItem.id ? "grey" : "transparent",
      }}
      onClick={() => handleSubMenuClick(subMenuItem)}
    >
      <ListItemText style={{ paddingLeft: "33px" }} primary={subMenuItem.name} />
    </ListItemButton>
  ))}
</List>
      </Collapse>
    )}

        </div>
      ))}
    </SubMenuWrapper>
    <Grid sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none', xxl: 'none' } }}>
      <Box sx={{ width: '100%', position: 'fixed', bottom: 0 }}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Recents" icon={<TableChartTwoToneIcon />} />
          <BottomNavigationAction label="Favorites" icon={<TableChartTwoToneIcon />} />
          <BottomNavigationAction label="Nearby" icon={<TableChartTwoToneIcon />} />
        </BottomNavigation>
      </Box>
    </Grid>
  </List>
</MenuWrapper>
  );
}

export default SidebarMenu;

