import React, { ChangeEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Autocomplete, Box, Button, Grid, MenuItem } from '@mui/material';
import { BaseAPI } from 'src/providers/base-api';

function SearchFilters(
  props: any
) {

  function onChangeValue(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    props.setAdvanceFilter({ ...props.advanceFilter, [e.target.name]: e.target.value });
  }
  const OfficeState = [
    {
      label: 'ChennaiOffice-403',
      value: '403'
    },
    {
      label: 'PondicherryOffice',
      value: 'Pondi'
    },

    {
      label: 'ChennaiOffice-405',
      value: '405'
    },
];
  const Attendencetype = [
    {
      label: 'Permission',
      value: 'permission'
    },

  ];
  const uniqueEmployeeNames = [...new Set<string>(props.employeeNames)];
  const sortedAlphabeticalEmployeeNames = [...uniqueEmployeeNames].sort((a: string, b: string) => a.localeCompare(b));


  const uniqueReportedEmployeeNames = [...new Set<string>(props.namesearchData)];
  const sortedAlphabeticalReportedEmployeeNames = [...uniqueReportedEmployeeNames].sort((a: string, b: string) => a.localeCompare(b));

  const ticketStatus = [...new Set<string>(props.ticket_status)];
  const status_ticket = [...ticketStatus].sort((a: string, b: string) => a.localeCompare(b));

  const uniqueEmployeeNames1 = [...new Set<string>(props?.username)];
  


  const workType = [
    {
      label: 'Regular',
      value: '55'
    },
    {
      label: 'Shift',
      value: '56'
    },
  ];

  const sortOptions = [
    { label: 'Ticket ID ASC', value: 'idAsc' },
    { label: 'Ticket ID DESC', value: 'idDesc' },
    { label: 'Assign To ASC', value: 'assignToAsc' },
    { label: 'Assign To DESC', value: 'assignToDesc' },
    { label: 'Assigned By ASC', value: 'assignedByAsc' },
    { label: 'Assigned By DESC', value: 'assignedByDesc' },
  ];
  const handleSortChange = (e) => {
    props.setSortOrder(e.target.value);
  };
  return (
    <>
      <Grid container  >
      <Grid item>
  {props.isTicket && (
    <TextField
      sx={{ marginRight: '10px' }}
      placeholder="Enter Ticket ID"
      className="search-input"
      autoComplete='off'
      type="text"
      value={props.idValue}
      inputProps={{
        style: { height: '18px' }
      }}
      onChange={(e) => props.setIdValue(e.target.value)}
    />
  )}

  {props.isTicketTool && (
    <Autocomplete
      id="employee-name-autocomplete"
      options={sortedAlphabeticalEmployeeNames}
      sx={{
        '.MuiAutocomplete-clearIndicator': {
          display: 'none',
        },
        width: 250,
        marginRight: '10px',
      }}
      value={props.employeeName}
      clearIcon={null} 
      onChange={(_, newValue) => {
        props.setEmployeeName(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter Handled By"
          sx={{ style: { height: "14px" } }}
        />
      )}
    />
)}

  {!props.isTicket && !props.isTicketTool && (
    <TextField
      sx={{ marginRight: "10px" }}
      placeholder="Enter Employee ID"
      className="search-input"
      type="text"
      value={props.employeeId}
      inputProps={{
        style: { height: "18px" }
      }}
      onChange={(e) => {
        props.setEmployeeId(e.target.value);
      }}
    />
  )}
</Grid>
        
        {props.isTicket && (
          <Grid item >
            <TextField
              sx={{ marginRight: '10px' }}
              placeholder="Enter Assigned By"
              className="search-input"
              autoComplete='off'
              type="text"
              inputProps={{
                style: { height: '18px' }
              }}
              value={props.assignedBy}
              onChange={(e) => props.setSearchName(e.target.value)}
            />
          </Grid>
          
        ) }  


{props.isTicketTool && (
          <Grid item >
            <Autocomplete
      id="employee-name-autocomplete"
      options={sortedAlphabeticalReportedEmployeeNames}
      sx={{
        '.MuiAutocomplete-clearIndicator': {
          display: 'none',
        },
        width: 250,
        marginRight: '10px',
      }}
      value={props.reportedName}
      clearIcon={null} 
      onChange={(_, newValue) => {
        props.setreportedName(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter Reported By"
          sx={{ style: { height: "14px" } }}
        />
      )}
    />
          </Grid>
          
        ) } 

{props.isTicketTool && (
          <Grid item >
            <Autocomplete
      id="employee-name-autocomplete"
      options={status_ticket}
      sx={{
        '.MuiAutocomplete-clearIndicator': {
          display: 'none',
        },
        width: 250,
        marginRight: '10px',
      }}
      value={props.statusName}
      clearIcon={null} 
      onChange={(_, newValue) => {
        props.setstatusName(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter Status"
          sx={{ style: { height: "14px" } }}
        />
      )}
    />
          </Grid>
          
        ) } 

        {props.isTicket && (
          <Grid item>
            <TextField
              sx={{ marginRight: '10px' }}
              placeholder="Enter Assign To"
              className="search-input"
              autoComplete='off'
              type="text"
              inputProps={{
                style: { height: '18px' }
              }}
              value={props.assignToValue}
              onChange={(e) => props.setUserName(e.target.value)}
            />
          </Grid>
        )}
        {props.isTicket && (
          <Grid item>
            <TextField
              sx={{ marginRight: '10px', textAlign: 'left', width: '250px' }}
              id="sortDropdown"
              label="Sort By Field"
              select
              value={props.sortOptions}
              onChange={handleSortChange}
              inputProps={{
                style: { height: "18px" }
              }}
            >
              {sortOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}

        <Button type="button" sx={{ py: "0px" }}
          onClick={props.getFilterDataFunction} color="inherit">
          <SearchIcon fontSize="large" />
        </Button>
        <Button type="button" sx={{ py: "0px" }} onClick={props.onClear}>
          <CloseOutlinedIcon fontSize="large" />
        </Button>
        {!props.isTicketTool && (
        <Button onClick={props.toggleAdvancedSearch} sx={{ py: "0px" }} >
          {props.advancedSearch ? 'Hide Advanced Search' : 'Advanced Search'}
        </Button>
        )}
      </Grid>

      <Grid item xs={12} sx={{ py: "7px" }}     >
        {props.stringData === "WorklogAdmin" &&
          props.advancedSearch && (
            <Grid sx={{ py: "7px" }} >
              {props.advancedSearchFields.map((field) => (
                <TextField sx={{ marginRight: "10px" }}
                  key={field.name}
                  placeholder={field.placeholder}
                  inputProps={{
                    style: { height: "18px" }
                  }}
                  className="search-input"
                  type="text"
                  value={
                    field.name === "status" ? props.namesearchData :
                      field.name === "taskID" ? props.addresssearchData :
                        field.name === "CATEGORY" ? props.category : ""
                  }
                  onChange={(e) => {
                    if (field.name === "status") {
                      props.setNameSearchData(e.target.value);
                    } else if (field.name === "taskID") {
                      props.setAddressSearchData(e.target.value);
                    } else if (field.name === "CATEGORY") {
                      props.setCategory(e.target.value);
                    }
                  }}
                  label={field.label}
                />
              ))}
            </Grid>
          )
        }
        {props.stringData === "WorklogUser" &&
          props.advancedSearch && (
            <Grid sx={{ py: "7px" }} >
              {props.advancedSearchFields.map((field) => (
                <TextField sx={{ marginRight: "10px" }}
                  key={field.name}
                  placeholder={field.placeholder}
                  inputProps={{
                    style: { height: "18px" }
                  }}
                  className="search-input"
                  type="text"
                  value={
                    field.name === "fname" ? props.namesearchData :
                      field.name === "lname" ? props.addresssearchData :
                        field.name === "status" ? props.category : ""
                  }
                  onChange={(e) => {
                    if (field.name === "status") {
                      props.setNameSearchData(e.target.value);
                    } else if (field.name === "taskID") {
                      props.setAddressSearchData(e.target.value);
                    } else if (field.name === "CATEGORY") {
                      props.setCategory(e.target.value);
                    }
                  }}
                  label={field.label}
                />
              ))}
            </Grid>
          )
        }

        {props.stringData === "biometric" &&
          props.advancedSearch && (
            <Grid sx={{ py: "7px" }} >

              {props.advancedSearchFields.map((field) => (
                <TextField
                  sx={{ marginRight: "10px", width: '13%' }}
                  key={field.name}
                  placeholder={field.placeholder}
                  inputProps={{
                    style: { height: "18px" }
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  className="search-input"
                  type={field.name === "From" || field.name === "To" ? "date" : "text"}
                  value={
                    field.name === "From" ? props.enteredStartDate :
                      field.name === "To" ? props.enteredEndDate :
                        field.name === "Select Attendence Type" ? props.selectedAttendenceState :
                          field.name === "Select Office" ? props.selectedState :
                            field.name === "workType" ? props.selectedworkTypeState : ''
                  }

                  onChange={(e) => {
                    if (field.name === "From") {
                      props.setEnteredStartDate(e.target.value);
                    } else if (field.name === "To") {
                      props.setEnteredEndDate(e.target.value);
                    } else if (field.name === "Select Office") {
                      props.setSelectedState(e.target.value);
                    }

                    else if (field.name === "Select Attendence Type") {
                      props.setselectedlAttendenceState(e.target.value);
                    }
                    else if (field.name === "workType") {
                      props.setselectedworkTypeState(e.target.value);
                    }
                  }}
                  label={field.label}
                  SelectProps={field.name === "Select Office" || field.name === "Select Attendence Type" || field.name === "workType" ? { native: false } : undefined}
                  select={field.name === "Select Office" || field.name === "Select Attendence Type" || field.name === "workType"}
                >
                  {field.name === "Select Office" ? (
                    OfficeState.map((office) => (
                      <MenuItem key={office.value} value={office.value}>
                        {office.label}
                      </MenuItem>
                    ))
                  ) : null}

                  {field.name === "Select Attendence Type" ? (
                    Attendencetype.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  ) : null}
                  {field.name === "workType" ? (
                    workType.map((option: any) => (
                      <MenuItem key={option?.value} value={option?.value}>
                        {option.label}
                      </MenuItem>
                    ))
                  ) : null}
                </TextField>

              ))}
            </Grid>
          )
        }

      </Grid>
      <Grid item xs={12} sx={{ py: "7px" }}     >
        {props.stringData === "isTicketLogin" &&
          props.advancedSearch && (
            <Grid sx={{ py: "7px" }} >

              {props.advancedSearchFields?.map((field: any) => (
                <TextField sx={{ marginRight: "10px" }}
                  key={field.name}
                  name={field.name}
                  placeholder={field.placeholder}
                  autoComplete='off'
                  inputProps={{
                    style: { height: "18px" }
                  }}
                  className="search-input"
                  type="text"
                  value={props.advanceFilter[field.name]}
                  onChange={onChangeValue}
                  label={field.label}
                />
              ))}
            </Grid>
          )
        }

      </Grid>

      
    </>
  );
}

export default SearchFilters;