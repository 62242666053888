import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";

export const getUsers: any = createAsyncThunk('getUsers', async() => {
    return Axios.get('http://localhost:3000/api/users').then(res => {
        return res.data
    })
})



const usersSlice = createSlice({
    name : 'users',
    initialState : {
        users : [],
        loading: false
    },
    reducers: {},
    extraReducers: {
        [getUsers.pending] : (state,action) => {
            state.loading = true
        },
        [getUsers.fulfilled] : (state,action) => {
            state.loading = false,
            state.users = action.payload
        },
        [getUsers.rejected] : (state,action) => {
            state.loading = false
        }
    },
})

export default usersSlice.reducer;