import axios from 'axios';
import { useNavigate } from 'react-router';

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((config) => {
    if (config.url.includes('/auth/')) {
        return config;
    }

    const user = JSON.parse(localStorage.getItem('UserDetail') || '{}');
    if (user?.access_token) {
        config.headers.Authorization = user.access_token;
    }

    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                // localStorage.clear();
                // navigate(`/login`);
                // Handle unauthorized error (e.g., logout)
                // Example: You can redirect the user to the login page or perform any other actions
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
