import { Card, Grid } from '@mui/material';
import RecentOrdersTable from './TicketTable';
import { useEffect, useState } from 'react';
import { BaseAPI } from 'src/providers/base-api';
import { useParams } from 'react-router';
import '../Tickets/Tickets.css'
import SearchFilters from '../WorkLog/searchComponent';

type AdvanceFilterProps = { status: string, project:string};

const advancedSearchFields = [
  {
    name: "status",
    placeholder: "Enter Status",
    label: "Status",
  },
  {
    name: "project",
    placeholder: "Enter project",
    label: "Project",
  },
];

function RecentOrders() {
  const [ticketData, setTicketData] = useState([]);
  const [adminTicket,setAdminTicket] = useState([]);
  const [offset, setOffset] = useState(0);
  const [searchFilter, setSearchFilter] = useState(null);
  const [totalPages, setTotalPage] = useState(1);
  const [commentStatus, setComentStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [OptionUserType, setOptionUserType] = useState<any[]>([]);
  const [ticketId, setTicketId] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [assignTo, setAssignTo] = useState('');
  const [assignedBy, setAssignedBy] = useState('');
  const [namesearchData, setNameSearchData] = useState<any>("");
  const [addresssearchData, setAddressSearchData] = useState("");
  const [advanceFilter, setAdvanceFilter]=useState<AdvanceFilterProps>({
  status:'',
  project:'',
})
   const [users, setUsers] = useState();

  const [partyData,setPartyData] = useState([]);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  

  const getPartyDetails = async () => {
    const getUser = JSON.parse(localStorage.getItem('UserDetail'));
    const getUserEmail = getUser?.email;
    try {
      await BaseAPI.getPartyDetails((result: any) => {
        
        if (result.status === 200) {
          const filteredValue = result.data.filter(
            (item: any) => item.email == getUserEmail
          );
          setPartyData(filteredValue);
        } else {
          console.log(result.message);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };


  const getCodeValueRef = async () => {

    try {
      await BaseAPI.getCodeReference(async (result: any) => {
        result.data.codeTables.map((dataValue: any) => {
          switch (dataValue.typeCode) {
            case 'user_ref': {

              setOptionUserType(dataValue.values);
              const filterValue = dataValue.values.filter((dataValue: any) => {
                return dataValue.id

              })

              break;
            }


            default: {
              "No Code Reference Value Added"
              break;
            }
          }
        })

      });
    }

    catch (error) {
      console.log(error);
    }

  };

const testPartytype = partyData[0]?.party?.partyType

  const partyType = OptionUserType?.find((usertype: any) => 
              
  Number(usertype.id) === Number(testPartytype)
 );



  const getTickets = async (filter = null) => {
  
    try {
      
      await BaseAPI.ticketList(filter, (result: any) => {
        const getUser = JSON.parse(localStorage.getItem('UserDetail'));
        const getUserEmail = getUser?.email;
    
        if (result.status === 200) {
          const allTickets = result.data.ticket;
          const Tickets = allTickets.sort((ticketA, ticketB) => ticketA.id - ticketB.id);
          setAdminTicket(Tickets);
          const filteredTickets = allTickets.filter(
            (item) => item.ticketStatus[0].user.email === getUserEmail
          );

          setTicketData(filteredTickets);
          setTotalPage(result.data?.totalPage);


        } else {
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getComment = async () => {
    try {
      await BaseAPI.getCommentList((result: any) => {
        if (result.status === 200) {
          setComentStatus(result.data.data);
        } else {
        }
      });
    } catch (e) {
      console.log(e);
    }
  };


  // useEffect(() => {
  //   setIsLoading(true);
  //   getTickets({ searchFilter, offset });
  //   getComment();
  //   setIsLoading(false);
  //   // if (user) {
  //   //   axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
  //   // }
  //   if (ticketId) {
  //     BaseAPI.getTicketByID(ticketId, (result) => {
  //       if (result?.status == 200) {
  //         const data = result?.data?.data;
  //         data.user = [];
  //         data?.ticketStatus?.map((item) => {
  //           data.user.push({ value: item.user.id, label: item.user.firstName });
  //         });

  //         data.project = {
  //           value: data.project.id,
  //           label: data.project.projectName,
  //         };
  //       }
  //     });
  //   }
  // }
  // // , [user]
  //  );

  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };


  const getTicketDetails = async () => { 
    try {
      const filters: any = {
        assigned_By: assignedBy,
        assigned_To: assignTo,
        ticket_Id: ticketId,
        sorting: sortOrder,
        ...advanceFilter
      }
      await BaseAPI.getTicketFilter(filters,(result: any) => {
        if (result.status === 200) {
          setUsers(result.data);
          setAdminTicket(result.data)
          setTicketData(result.data);
        } else {
          console.log(result.message);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };


  const onClear = async () => {
    setAdvanceFilter({status:'', project:''});
    setNameSearchData('');
    setAddressSearchData('');
    setTicketId('');
    setAssignedBy('');
    setAssignTo('');
    setSortOrder('');
    getTicketDetails();
    getTickets();
  };

  useEffect(() => {
    setIsLoading(true);
    getTickets({ searchFilter, offset });
    getComment();
    getPartyDetails();
    getCodeValueRef();
    getTicketDetails();
    setIsLoading(false);
    getTickets();
  }, [testPartytype]);

  return (
    <><Grid sx={{ paddingTop: '24px', paddingLeft:'20px' }}><SearchFilters 
    isTicket = {true}
    name='ticket'
    idValue={ticketId}
    assignToValue={assignTo}
    setIdValue={setTicketId}
    sortOptions={sortOrder}
    setSortOrder={setSortOrder}
    onClear={onClear}
    setSearchName={setAssignedBy}
    setUserName={setAssignTo}
    advanceFilter={advanceFilter}
    setAdvanceFilter={setAdvanceFilter}
    advancedSearch={showAdvancedSearch}
    advancedSearchFields={advancedSearchFields}
    getFilterDataFunction={getTicketDetails}
    toggleAdvancedSearch={toggleAdvancedSearch}
    namesearchData={namesearchData}
    assignedBy={assignedBy}
    addresssearchData={addresssearchData}
    stringData="isTicketLogin"/>
    <>
    </>
    </Grid>
    <Card>

      {partyType?.name == "Super Admin" || partyType?.name == "Admin" ? (
        <RecentOrdersTable assignTicket={adminTicket} user={users} />
      ) : (
        <RecentOrdersTable assignTicket={ticketData} user={users} />
      )}
    </Card>
     </>
  );
}

export default RecentOrders;
