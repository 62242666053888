import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import PersonSlide from './content/pages/Components/PersonSlide';
import Projects from './content/applications/Projects';
import Tickets from './content/applications/Tickets';
import AddProjects from './content/applications/AddProjects';
import AddTickets from './content/applications/AddTickets';
import AddUsers from './content/applications/AddUsers';
import ViewTickets from './content/applications/ViewTickets';  
import TicketTool from './content/applications/Tickettool/Tickettool';
import AddTicketTool from './content/applications/Tickettool/AddTickettool';
import ViewTicketTool from './content/applications/Tickettool/ViewTickettool';
import EditTicketTool from './content/applications/Tickettool/EditTickettool';

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));
const LoginLandingPage = Loader(lazy(() => import('src/content/pages/Components/Login/LandingPage')));
const SignIn = Loader(lazy(() => import('src/content/pages/Components/Login/SignInPage')));
const ForgotPassword = Loader(lazy(() => import('src/content/pages/Components/Login/ForgotPasswordPage')));
const ResetPassword = Loader(lazy(() => import('src/content/pages/Components/Login/ResetPasswordPage')));


// Dashboards

const Dashboard = Loader(lazy(() => import('src/content/dashboardpage/dashboard')));

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);
const MyDetailsInfo = Loader(
  lazy(() => import('src/content/applications/MyDetailsInfo'))
);
const MyDetails = Loader(
  lazy(() => import('src/content/applications/MyDetails'))
);


const Expense = Loader(
  lazy(() => import('src/content/applications/expense'))
);

const AddExpenseDetails = Loader(
  lazy(() => import('src/content/applications/addExpense/addExpenseDetails'))
);

const ViewExpense = Loader(
  lazy(() => import('src/content/applications/addExpense/viewExpense'))
);

const WorkLog = Loader(
  lazy(() => import('src/content/applications/WorkLog'))
);
const TableWorklog = Loader(
  lazy(() => import('src/content/applications/WorkLog/TableWorklog'))
);
const AddWorkLog = Loader(
  lazy(() => import('src/content/applications/WorkLog/AddWorkLog'))
);
const EditWorklog = Loader(
  lazy(() => import('src/content/applications/WorkLog/EditWorklog'))
);
const Employees = Loader(
  lazy(() => import('src/content/applications/MyDetails/Employees'))
);

const ViewWorklog = Loader(
  lazy(() => import('src/content/applications/WorkLog/ViewWorklog'))
);

const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);
const EmployeeDirectory = Loader(
  lazy(() => import('src/employee-directory'))
);

const AttendenceUpload = Loader(
  lazy(() => import('src/content/applications/attendenceUpload'))
);


const AttendenceList = Loader(
  lazy(() => import('src/content/applications/attendenceLists'))
);

const PermissionsList = Loader(
  lazy(() => import('src/content/applications/permissionList'))
);

const Permissions = Loader(
  lazy(() => import('src/content/applications/permission'))
);

const LeaveTableList = Loader(
  lazy(() => import('src/content/applications/Leave'))
);


const LeaveTypeList = Loader(
  lazy(() => import('src/content/applications/LeaveType'))
);
const AddLeave = Loader(
  lazy(() => import('src/content/applications/Leave/AddLeave'))
);

const ViewLeave = Loader(
  lazy(() => import('src/content/applications/Leave/ViewLeave'))
);

const EditLeave = Loader(
  lazy(() => import('src/content/applications/Leave/EditLeaveRequest'))
);

const LeaveTypeCreate = Loader(
  lazy(() => import('src/content/applications/LeaveType/AddLeaveType'))
)

const UpdateLeaveType = Loader(
  lazy(() => import('src/content/applications/LeaveType/UpdateLeaveType'))
)

const ViewLeaveType = Loader(
  lazy(() => import('src/content/applications/LeaveType/viewLeaveType'))
)

const OrganisationProfile = Loader(
  lazy(() => import('src/content/applications/Settings/datepage'))
);

const OrganisationProfileUpdate = Loader(
  lazy(() => import('src/content/applications/Settings/organisationProfileUpdate'))
);

const AddWorkLocations = Loader(
  lazy(() => import('src/content/applications/Settings/AddworkLocations'))
);

const WorkLocationEdit = Loader(
  lazy(() => import('src/content/applications/Settings/WorkLocationEdit'))
);

const WorkLocationList = Loader(
  lazy(() => import('src/content/applications/Settings/worklocationData'))
);

const AddDepartments = Loader(
  lazy(() => import('src/content/applications/Settings/AddDepartments'))
);
const DepartmentsList = Loader(
  lazy(() => import('src/content/applications/Settings/departmentData'))
);
const EditDepartments = Loader(
  lazy(() => import('src/content/applications/Settings/departmentEdit'))
);

const AddDesignation = Loader(
  lazy(() => import('src/content/applications/Settings/AddDesignation'))
);
const EditDesignation = Loader(
  lazy(() => import('src/content/applications/Settings/designationEdit'))
);
const DesignationList = Loader(
  lazy(() => import('src/content/applications/Settings/designationData'))
);
const UserDetailsEdit = Loader(
  lazy(() => import('src/User-Details/ViewUser'))
);

const UserView = Loader(
  lazy(() => import('src/User-Details/Users/detailViewUser'))
);

const UserDetails = Loader(
  lazy(() => import('src/User-Details/AddUser'))
);

const UserDetailsList = Loader(
  lazy(() => import('src/User-Details/Users'))
);





const CodeReferenceList = Loader(
  lazy(() => import('src/CodeReference/CodeReferenceListPage'))
)

const CodeReference = Loader(
  lazy(() => import('src/CodeReference/AddCodeReference'))
);

const CodeReferenceEdit = Loader(
  lazy(() => import('src/CodeReference/UpdatecodeReference'))
);

const Roles = Loader(
  lazy(() => import('src/RolesManagement/RolesListPage.tsx'))
);

const AddRolesManagement = Loader(
  lazy(() => import('src/RolesManagement/AddRolesManagement'))
);

const EditsRolesManagement = Loader(
  lazy(() => import('src/RolesManagement/AddRolesManagement'))
);

const EditRolesManagement = Loader(
  lazy(() => import('src/RolesManagement/EditRolesManagement'))
);


// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <LoginLandingPage />
      },
      {
        path: '/login',
        element: <SignIn />
      },
      {
        path: '/forget-password',
        element: <ForgotPassword />
      },
      {
        path: '/resetpassword/:id',
        element: <ResetPassword />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },

  {
    path: 'employee',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },

      {
        path: 'employee-details',
        element: < MyDetails />
      },
      {
        path: 'MyDetails',
        element: <MyDetailsInfo />
      },
      {
        path: 'view/:id',
        element: <Employees />
      }

    ]
  },

  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'attendence-upload',
        element: <AttendenceUpload />
      },
      {
        path: 'attendence-lists',
        element: <AttendenceList />
      },
      {
        path: 'permissions',
        element: <PermissionsList />
      },

      {
        path: 'permissions-lists/:id',
        element: <Permissions />
      },


      {
        path: 'expense',
        element: <Expense />
      },

      {
        path: 'expense/:userExpenseId/:itemId/view',
        element: <ViewExpense />
      },


      {
        path: 'addExpenseDetails/:actions',
        element: <AddExpenseDetails />
      },

      {
        path: 'expense/:userExpenseId/:itemId/:actions',
        element: <AddExpenseDetails />
      },

      {
        path: 'worklog',
        element: <WorkLog />
      },


      {
        path: 'workloglist/:id',
        element: <TableWorklog />
      },
      {
        path: 'addworklog',
        element: <AddWorkLog />
      },
      {
        path: 'viewworklog/:id',
        element: <ViewWorklog />
      },
      {
        path: 'editworklog/:id',
        element: <EditWorklog />
      },

      {
        path: 'tickettoollist',
        element: <TicketTool />
      },


      // {
      //   path: 'workloglist/:id',
      //   element: <TableWorklog />
      // },
      {
        path: 'addtickettool',
        element: <AddTicketTool />
      },
      {
        path: 'viewtickettool/:id',
        element: <ViewTicketTool />
      },
      {
        path: 'edittickettool/:id',
        element: <EditTicketTool />
      },

      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },

  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'codeReference',
        element: <CodeReference />
      },

      {
        path: 'codeReferenceList',
        element: <CodeReferenceList />
      },

      {
        path: 'codeReference-edit/:id',
        element: <CodeReferenceEdit />
      },
    ]

  },

  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'roles',
        element: <Roles />
      },
      {
        path: 'add-roles',
        element: <AddRolesManagement />
      },
      {
        path: 'edit-roles/:id',
        element: <AddRolesManagement />
      }
    ]

  },

  // employee-list
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'employeeList',
        element: <EmployeeDirectory />
      },
    ]

  },


  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'userDetails',
        element: <UserDetails />
      },

      {
        path: 'userList',
        element: <UserDetailsList />
      },

      {
        path: 'userEdit/:id',
        element: <UserDetailsEdit />
      },
      {
        path: 'viewUser/:id',
        element: <UserView />
      },
    ]

  },
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="transactions" replace />
      },
      {
        path: 'leaveList',
        element: <LeaveTableList />
      },
      {
        path: 'addLeaveRequest',
        element: <AddLeave />
      },
      {
        path: 'viewLeave/:id',
        element: <ViewLeave />
      },
      {
        path: 'editLeaveRequest/:id',
        element: <EditLeave />
      },
      {
        path: 'leaveTypeList',
        element: <LeaveTypeList />
      },
      {
        path: 'leaveTypeCreate',
        element: <LeaveTypeCreate />
      },
      {
        path: 'upDateLeaveType/:id',
        element: <UpdateLeaveType />
      },
      {
        path: 'viewLeaveType/:id',
        element: <ViewLeaveType />
      },
      {
        path: 'organisationprofile',
        element: <OrganisationProfile />
      },
      {
        path: 'organisationprofile/edit/:id',
        element: <OrganisationProfileUpdate />
      },
      {
        path: 'work-locations/add',
        element: <AddWorkLocations />
      },
      {
        path: 'work-locations/edit/:id',
        element: <WorkLocationEdit />
      },
      {
        path: 'work-locations/list',
        element: <WorkLocationList />
      },
      {
        path: 'department/add',
        element: <AddDepartments />
      },
      {
        path: 'department/list',
        element: <DepartmentsList />
      },
      {
        path: 'department/edit/:id',
        element: <EditDepartments />
      },
      {
        path: 'designation/add',
        element: <AddDesignation />
      },
      {
        path: 'designation/edit/:id',
        element: <EditDesignation />
      },
      {
        path: 'designation/list',
        element: <DesignationList />
      },
    ]

  },


  //Taskmodule
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="user" replace />
      },

      {
        path: '',
        element: <Navigate to="projects" replace />
      },
      {
        path: 'projects',
        element: <Projects />
      },
      {
        path: 'projects/:id',
        element: <AddProjects />
      },
      {
        path: '',
        element: <Navigate to="tickets" replace />
      },
      {
        path: 'tickets',
        element: <Tickets />
      },

      {
        path: 'edittickets/:id',
        element: <AddTickets />
      },

      {
        path: 'addtickets',
        element: <AddTickets />
      },
      {
        path: 'addticket/:id',
        element: <AddTickets />
      },
      {
        path: 'addusers',
        element: <AddUsers />
      },
      {
        path: 'users/:id',
        element: <AddUsers />
      },
      {
        path: 'addprojects',
        element: <AddProjects />
      },
      {
        path: 'viewtickets/:id',
        element: <ViewTickets />
      },



    ]
  },


  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'PersonSlide',
        element: <PersonSlide />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
