import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import { BaseAPI } from 'src/providers/base-api';
import RecentOrdersTable from './ProjectTable';


function Project() {

  const [searchFilter, setSearchFilter] = useState(null);
  const [offset, setOffset] = useState(0);
  const [projectData, setProjectData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPage] = useState(1);
  const getProjects = async (filter = null) => {
    try {
      await BaseAPI.projectList(filter, (result: any) => {
        if (result.status === 200) {
          
          setProjectData(result.data?.data);
          
          setTotalPage(result.data?.totalPage);
        } else {
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    getProjects({ searchFilter, offset });
    setIsLoading(false);
  }, []);
  


  return (
    <Card>
      <RecentOrdersTable projectOrders={projectData} />
    </Card>
  );
}

export default Project;
