import {
  alpha, Badge, Box, Button, Card,
  Divider, IconButton, List, ListItem, Popover,
  Popper, Tooltip, Typography
} from '@mui/material';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import { useEffect, useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router';
import './notifications.css';
const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            right: 10px;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [category, setCategory] = useState<any>();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const OrganisationProfile = () => {
    navigate("/organisationprofile");
    setAnchorEl(null);
  };
  const WorkLocations =()=>{
    navigate("/work-locations/list");
    setAnchorEl(null);
  }
  const Departments =()=>{
    navigate("/department/list");
    setAnchorEl(null);
  }
  const Designation =()=>{
    navigate("/designation/list");
    setAnchorEl(null);
  }
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleOpen = (): void => {
    setOpen(true);
  };


  const getSideBarDate = async () => {
    const dataSidebar = JSON.parse(localStorage.getItem('operations'))
    dataSidebar.map((dataValue: any) => {
      switch (dataValue.refName) {
        case 'Settings': {
          setCategory(dataValue?.refName)
          break;
        }

        default: {
          "No  Value Added"
          break;
        }
      }
    })
  }

  useEffect(() => {
    getSideBarDate();

  }, []);



  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >   <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      {category == 'Settings' ?
        <Tooltip arrow title="Settings">
          <IconButton color="primary" ref={ref}
            aria-describedby={id}
            onClick={handleClick}
          >
            <NotificationsBadge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <SettingsOutlinedIcon />
            </NotificationsBadge>
          </IconButton>
        </Tooltip>
        : <></>}
     
      <Popover
        sx={{ top: '70px', marginRight: '10px', backdropFilter: 'blur(0px)' }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      // anchorOrigin={{
      //   vertical: 'top',
      //   horizontal: 'right'
      // }}
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'right'
      // }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Notifications</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          <ListItem
            sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
          >
            <Box flex="1">
              <Box display="flex" justifyContent="space-between">
                <Typography sx={{ fontWeight: 'bold' }}>
                  Messaging Platform
                </Typography>
                <Typography variant="caption" sx={{ textTransform: 'none' }}>
                </Typography>
              </Box>
              <Typography
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {' '}
                new messages in your inbox
              </Typography>
            </Box>
          </ListItem>
        </List>
      </Popover>
      <Popover
        sx={{ top: '70px', marginRight: '10px', backdropFilter: 'blur(0px)' }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disablePortal={false}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3">Settings</Typography>
        </Box><Divider />
        <List sx={{ p: 0 }}>
          <ListItem
            sx={{ p: 1, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
          >
            <Box flex="1" sx={{ display: "contents" }}>
              <CorporateFareOutlinedIcon />
              <Box display="flex" justifyContent="space-between">
                <Button sx={{ color: "#5c5454" }} onClick={() => OrganisationProfile()}>Organisation Profile</Button>
              </Box>
            </Box>
          </ListItem>
        </List>
        <List sx={{ p: 0 }}>
          <ListItem
            sx={{ p: 1, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
          >
            <Box flex="1" sx={{ display: "contents" }}>
              <LocationOnOutlinedIcon />
              <Box display="flex" justifyContent="space-between">
                <Button sx={{ color: "#5c5454" }} onClick={() => WorkLocations()}>Work Locations</Button>
              </Box>
            </Box>
          </ListItem>
        </List>
        <List sx={{ p: 0 }}>
          <ListItem
            sx={{ p: 1, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
          >
            <Box flex="1" sx={{ display: "contents" }}>
              <LanOutlinedIcon />
              <Box display="flex" justifyContent="space-between">
                <Button sx={{ color: "#5c5454" }} onClick={() => Departments()}>Departments </Button>
              </Box>
            </Box>
          </ListItem>
        </List>
        <List sx={{ p: 0 }}>
          <ListItem
            sx={{ p: 1, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
          >
            <Box flex="1" sx={{ display: "contents" }}>
              <AssignmentIndOutlinedIcon />
              <Box display="flex" justifyContent="space-between">
                <Button sx={{ color: "#5c5454" }} onClick={() => Designation()}>Designation </Button>
              </Box>
            </Box>
          </ListItem>
        </List>
      </Popover>
      
    </>
  );
}

export default HeaderNotifications;
