import { FC, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Box,
  Card,
  MenuItem,
  Typography,
  TextField,
  Grid,
  Button
} from '@mui/material';
import { BaseAPI } from 'src/providers/base-api';
import { useNavigate, useParams } from 'react-router';
import { ProjectList, RecentOrderStatus } from 'src/models/crypto_order';
import { ProjectFormData } from 'src/models/CommonInterface';
import AlertComponent from 'src/User-Details/AlertComponent';
import schema from 'src/content/applications/MyDetailsInfo/Validations';
import IconButton from '@mui/material/IconButton';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Assuming you're using Material-UI icons

interface ProjectProps {
  className?: string;
  addProjects: ProjectList[];
}
interface Filters {
  status?: RecentOrderStatus;
}
const status = [
  {
    value: 'Open',
    label: 'Open'
  },
  {
    value: 'Closed',
    label: 'Closed'
  },
  {
    value: 'In-progress',
    label: 'In-progress'
  }
];

const AddProject: FC<ProjectProps> = ({ addProjects }) => {
  const fileInputRef = useRef(null);
  const [validated, setValidated] = useState(false);
  const [images, setImages] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [validationErrors, setValidationErrors] = useState<any>({});

  const params = useParams();
  const projectId: any = params.id ? params.id : undefined;
  const [errors, setErrors] = useState<any>({
    projectName: "",
    description: "",
    file: "",
    status: "",
  });
  const [form, setForm] = useState<ProjectFormData>({
    projectName: "",
    description: "",
    documentation: "",
    documentationName: "",
    status: "Open",
  });

  const validateForm = async () => {
    try {
      await schema.validate(form, { abortEarly: false });
      setValidationErrors({});
      return true;
    } catch (error) {
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setValidationErrors(errors);
      return false;
    }
  };

  const handleChange = (fileName, value) => {
    setForm((prevForm) => ({ ...prevForm, [fileName]: value }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fileName]: '',
    }));
    if (!!errors[fileName])
      setErrors({
        ...errors,
        [fileName]: null,
      });
  };

  function onFileBrowse() {
    fileInputRef.current.click();
  }

  const onChangeFile = ({ target }) => {
    const { files, name, value } = target as any;
    const fileName = files[0].name;
    setForm((prevForm) => ({ ...prevForm, [name]: fileName }));
    handleChange('documentation', fileName);
    setImages(files);
  };

  const navigate = useNavigate();
  const readFileAsDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleUpdate = async (event: any) => {
    const isValid = await validateForm();
    event.preventDefault();
    event.stopPropagation();
    const newErrors = validateForm();
    setValidated(true);
    setErrors(newErrors);
    if (!form?.projectName) {
      return;
    }
    if (!form?.description) {
      return;
    }
    try {
      const formdata = new FormData();

      if (images?.length) {
        formdata.append("file", images?.[0]);
        formdata.append("documentationName", images?.[0].name);

      }
      formdata.append("projectName", form.projectName);
      formdata.append("description", form.description);
      formdata.append("status", form.status);

      if (formdata) {
        await BaseAPI.updateProjectByID(formdata, projectId, (result: any) => {
          setErrorMessage(result?.response?.data?.errors?.username);
          if (result.status === 200 || result.status === 201) {

            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              navigate(`/management/projects`);
            }, 3000);

          } else {

          }
        });
      }

    } catch (error) {
      setErrorMessage(error?.response?.data?.errors?.username);
    }
    setValidated(true);
  };

  const handleSubmit = async (event: any) => {
    const isValid = await validateForm();
    event.preventDefault();
    event.stopPropagation();
    const newErrors = validateForm();
    setValidated(true);
    setErrors(newErrors);
    try {

      const formdata = new FormData();
      if (images?.length) {
        formdata.append("file", images?.[0]);
      }
      if (form.projectName?.length) {
        formdata.append("projectName", form.projectName);
      }
      if (form.description?.length) {
        formdata.append("description", form.description);
      }
      if (form.status?.length) {
        formdata.append("status", form.status);
      }
      await BaseAPI.addProject(formdata, (result: any) => {
        setErrorMessage(result?.response?.data?.errors?.username);
        if (result.status === 200 || result.status === 201) {

          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            navigate(`/management/projects`);
          }, 3000);

        } else {

        }
      });
    } catch (error) {
      setErrorMessage(error?.response?.data?.errors?.username);
    }
    setValidated(true);
  };

  const handleCancelChange = (event) => {
    navigate(`/management/projects`);

  }

  useEffect(() => {
    if (projectId) {
      BaseAPI.getProjectByID(projectId, (result) => {

        if (result?.status == 200) {
          const data = result?.data?.data;
          const urlObject = new URL(data?.documentation);
          const filename = urlObject.pathname.split('/').pop();
          setForm({
            projectName: data?.projectName,
            description: data?.description,
            documentation: data?.documentation,
            documentationName: filename,
            status: data?.status,
          });

        }
      });
    }
  }, [projectId]);

  return (
    <Card>
      <Typography variant="h3" component="h3" m={3} gutterBottom>
        {projectId ? 'Edit Project' : 'Add Project'}
      </Typography>

      <Divider />
      <Box
        component="form"
        onSubmit={projectId ? handleUpdate : handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <div className="ticket-fields" >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                required
                id="standard-required"
                label="Project Name"
                variant="standard"
                value={form.projectName}
                error={!!validationErrors.projectName}
                helperText={validationErrors?.projectName}
                name="projectName"
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }
                inputProps={{ maxLength: 50 }}
              />

            </Grid>
            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                required
                id="standard-required"
                label="Description"
                variant="standard"
                value={form.description}
                name="description"
                error={!!validationErrors.description}
                helperText={validationErrors?.description}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }
                inputProps={{ maxLength: 1000 }}
              />
            </Grid>
            <Grid item xs={4}>
              <input
                type="file"
                ref={fileInputRef}
                name='documentationName'
                style={{ display: 'none' }}
                onChange={onChangeFile}
              />
              <TextField
                style={{ width: '100%', bottom: '10px' }}
                className='doc-inputfiled'
                required
                autoComplete="off"
                type='text'
                id="standard-required"
                label="Documentation"
                variant="standard"
                name='documentationName'
                onClick={onFileBrowse}
                value={form.documentationName || ''}
                error={!!validationErrors.documentation}
                helperText={validationErrors?.documentation}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={onFileBrowse}
                      edge="end"
                    >
                      <InsertDriveFileIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className="Save-button">
          <div>
            <Grid xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button variant="outlined" sx={{ margin: 1 }}
                onClick={handleCancelChange}
              >Cancel</Button>
              <Button sx={{ margin: 1 }} variant="contained" color="primary"
                type="submit">
                {projectId ? "Update" : "Submit"}
              </Button>

            </Grid>
            {showAlert && <AlertComponent alerts={showAlert} message={projectId ? "Updated" : "Added"} />}
          </div>

        </div>
      </Box>


    </Card>
  );
};

AddProject.propTypes = {
  addProjects: PropTypes.array.isRequired
};

AddProject.defaultProps = {
  addProjects: []
};

export default AddProject;

