import { Typography, Button, Grid } from '@mui/material';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';


import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useEffect, useState } from 'react';
import { BaseAPI } from 'src/providers/base-api';
import { TicketDetails } from 'src/models/CommonInterface';

function PageHeader() {
  const user = {
    name: 'Catherine Pike',
    avatar: '/static/images/avatars/1.jpg'
  };
  const navigate = useNavigate();

  
  const [ticketDetails, setTicketDetails] = useState<TicketDetails>();

  const params = useParams();
  const ticketId: any = params.id ? params.id : undefined;

  const childtask = async () => {
    let data = ticketDetails

    localStorage.setItem('ticketflag', JSON.stringify(true));
    localStorage.setItem('ticketDetails', JSON.stringify(ticketDetails));

    navigate(`/management/addtickets`);
  };
  
  useEffect(() => {
    if (ticketId) {
        BaseAPI.getTicketByID(ticketId, (result) => {
          if (result?.status == 200) {
            const data = result?.data?.data;
            setTicketDetails(data);
          }
        });
      }
    }, [ ticketId]);
    
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          View Task
        </Typography>
      </Grid>
      <Grid item>
        <Button
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
          // component={RouterLink}
          // to="/management/addtickets"
          onClick={childtask}        >
          Child Task
        </Button>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
