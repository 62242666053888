import { FC, ChangeEvent, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  Grid,
  Hidden,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded';
import Label from 'src/components/Label';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import BulkActions from './BulkActions';
import { useNavigate } from 'react-router';
import { EmployeeStatus, UsersInterface } from 'src/models/CommonInterface';
import Paginations from 'src/employee-directory/pagination';
import { BaseAPI } from 'src/providers/base-api';
import AlertComponent from 'src/User-Details/AlertComponent';
import { CSVLink } from 'react-csv';
import ConfirmationModal from 'src/User-Details/Users/deletepopup';
interface UserListTableProps {
  activeStatus?: any;
  className?: string;
  ticketingToolData: UsersInterface[];
}

const getStatusLabel = (employeeStatus: EmployeeStatus): JSX.Element => {
  const map = {
    failed: {
      text: 'Failed',
      color: 'error'
    },
    completed: {
      text: 'Completed',
      color: 'success'
    },
    pending: {
      text: 'Pending',
      color: 'warning'
    }
  };

  const { text, color }: any = map[employeeStatus];

  return <Label color={color}>{text}</Label>;
};

const applyFilters = (ticketingToolData: any, filters: any) => {
  return ticketingToolData?.filter((ticketingToolData) => {
    let matches = true;
    if (filters.status && ticketingToolData.status !== filters.status) {
      matches = false;
    }
    return matches;
  });
};

const applyPagination = (ticketingToolData: any[], page: number, limit: number): any[] => {
  return ticketingToolData.slice(page * limit, page * limit + limit);
};

const UserListTable: FC<UserListTableProps> = ({ ticketingToolData }) => {
  const [selectedUser, setSelectedUser] = useState<string[]>([]);
  const selectedBulkActions = selectedUser.length > 0;
  const [filteredData, setFilteredData] = useState([]);
  const [OptionUserType, setOptionUserType] = useState<any>();
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertRestore, setShowAlertRestore] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [statusdata, setStatusdata] = useState<any>();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [EmployeeListData, setEmployeeListData] = useState<any>();
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [ticketIdToDelete, setTicketIdToDelete] = useState<any | null>(null);
  const [showActiveTickets, setShowActiveUsers] = useState(true);
  const [filters, setFilters] = useState<any>({
    status: null
  });
  const [filteredUserLists, setFilteredUserLists] = useState([]);
  const [paginatedUserLists, setPaginatedUserLists] = useState([]);

  const toggleTicketType = () => {
    setShowActiveUsers(!showActiveTickets);
    // activeStatus(!showActiveTickets)

  };
  const handleSelectAllCheckboxWorkLog = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedUser(
      event.target.checked
        ? ticketingToolData.map((item) => item.id)
        : []
    );
  };

  const handlePageChangeTicketingTool = (event: any, newPage: number): void => {
    setPage(newPage);
  };
  const handleLimitChangeTicketingTool = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const AddWorkLog = () => {
    navigate("/management/addtickettool");
  };

  const handleSelectDeleteCheckboxWorkLog = (
    event: ChangeEvent<HTMLInputElement>,
    deleteId: string
  ): void => {
    if (!selectedUser.includes(deleteId)) {
      setSelectedUser((prevSelected) => [
        ...prevSelected,
        deleteId
      ]);
    } else {
      setSelectedUser((prevSelected) =>
        prevSelected.filter((id) => id !== deleteId)
      );
    }
  };

  const getCodeValueRef = async () => {
    try {
      await BaseAPI.getCodeReference(async (result: any) => {
        result.data.codeTables.map((dataValue: any) => {
          switch (dataValue.typeCode) {
            case 'category_ref': {
              setOptionUserType(dataValue.values);
              break;
            }
            default: {
              "No Code Reference Value Added"
              break;
            }
          }
        })

      });
    }
    catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const handleClickViewWork = (id: any) => {
    navigate(`/management/viewtickettool/${id}`);
  }
  const handleClick = (id: any) => {
    navigate(`/management/edittickettool/${id}`);
  }
  const handleCancelDelete = () => {
    setConfirmationOpen(false);
  };
  const handleDeleteClick = (id: string) => {
    setTicketIdToDelete(id);
    setConfirmationOpen(true);
  };

  const handleTickettoolActive = async (id) => {
    try {
      await BaseAPI.activetickettool(id, (result: any) => {
        if (result.status === 200) {
          setShowAlertRestore(true);
          setTimeout(() => {
            setShowAlertRestore(false);
            window.location.reload();
          }, 3000);
        } else {
        }
        navigate(`/management/tickettoollist`);

      });
    } catch (e) {
      console.log(e);
    }
  };
  const handleTickettoolDelete = async () => {
    try {
      await BaseAPI.deletetickettool(ticketIdToDelete, (result: any) => {
        if (result.status === 200) {
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setConfirmationOpen(false);
            window.location.reload();
          }, 3000);
        } else {
        }
        navigate(`/management/tickettoollist`);

      });
    } catch (e) {
      console.log(e);
    }
  };
  const navigateToEditPage = (id: number) => {
    navigate(`/management/edittickettool/${id}`);
  };
  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const getStatusList = async () => {
    const dataSidebar = JSON.parse(localStorage.getItem('statusdata'))
    dataSidebar.map((dataValue: any) => {
      switch (dataValue.refName) {
        case 'status_ref': {
          setStatusdata(dataValue.values);
          break;
        }
        default: {
          "No  Value Added"
          break;
        }
      }
    })
  }

  const convertToIndianTime = (isoString: string): string => {
    // Define the options for formatting the date and time
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'Asia/Kolkata',  // Set the time zone to Indian Standard Time (IST)
      year: 'numeric',           // Display the full numeric year
      month: 'long',             // Display the full name of the month
      day: 'numeric',            // Display the numeric day of the month
      hour: '2-digit',           // Display the hour in 2-digit format
      minute: '2-digit',         // Display the minute in 2-digit format
    };

    // Create a new Date object from the ISO string
    const date = new Date(isoString);

    // Format the date using the specified options and return the formatted string
    return new Intl.DateTimeFormat('en-IN', options).format(date);
  };
  const truncateText = (text, length) => {
    if (text == null) {
      return '';
    }
    return text.length > length ? text.substring(0, length) + '...' : text;
  };



  
  useEffect(() => {
    const filtered = applyFilters(ticketingToolData, filters);
    setFilteredUserLists(filtered);
  }, [ticketingToolData, filters]);

  useEffect(() => {
    const paginated = applyPagination(filteredUserLists, page, limit);
    setPaginatedUserLists(paginated);
  }, [filteredUserLists, page, limit]);


  const selectedWorkLog =
    selectedUser.length > 0 &&
    selectedUser.length < ticketingToolData.length;
  const selectedAllWorkLog =
    selectedUser.length === ticketingToolData.length;
  const theme = useTheme();

  const getEmployeeListData = async () => {
    try {
      await BaseAPI.getPersonalInformation(async (result) => {
        setEmployeeListData(result.data)
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCodeValueRef();
    getEmployeeListData()

    // getStatusList();
  }, [])
  useEffect(() => {
    if (filteredUserLists?.length) {
      const namePattern = /"([^"]+)"\s*<[\w.+-]+@[\w.-]+\.[a-zA-Z]{2,7}>/g;
      filteredUserLists.forEach(user => {
        if (user?.reportedby) {
          const matches = [...(user.reportedby.matchAll(namePattern) || [])];
          const names = matches.map(match => match[1]);
          const nameString = names.join(', ');

          if (EmployeeListData?.length && !nameString) {
            const onlyEmailReportedBy = EmployeeListData.find(list => list.email === user.reportedby);
            user.reportedByName = onlyEmailReportedBy ? `${onlyEmailReportedBy.firstName} ${onlyEmailReportedBy.lastName}` : user.reportedby;
          } else {
            user.reportedByName = nameString || user.reportedby;
          }
        } else {
          user.reportedByName = user?.reportedby;
        }
      });
    }
  }, [filteredUserLists, EmployeeListData]);
  return (
    <Card sx={{ paddingTop: '20px' }}>
      {selectedBulkActions && (
        <Box flex={1} p={2}>
          <BulkActions />
        </Box>
      )}
      {!selectedBulkActions && (

        // <Grid container>
        //   <Grid item sm={6}>
        //     <Typography
        //       variant="h2"
        //       color="text.secondary"
        //       style={{ display: 'flex', alignItems: 'center' }}
        //     >
        //       Ticket List
        //       <IconButton onClick={handleMenuOpen}>
        //         <ArrowDropDownIcon />
        //       </IconButton>
        //     </Typography>
        //     <Menu
        //       anchorEl={document.getElementById('menu-anchor')}
        //       open={isMenuOpen}
        //       onClose={handleMenuClose}
        //     >
        //       <MenuItem onClick={handleMenuClose}>Dropdown Item 1</MenuItem>
        //       <MenuItem onClick={handleMenuClose}>Dropdown Item 2</MenuItem>
        //     </Menu>
        //   </Grid>
        <Grid container>
          <Grid item sm={6} sx={{ alignItems: "center", display: "flex", padding: "20px" }}>
            <Typography variant="h2" color="text.secondary" style={{ alignItems: "center", display: "flex", whiteSpace: "nowrap" }} onClick={toggleTicketType}>
              {/* {showActiveTickets ? 'Active Tickets' : 'Inactive Tickets'}{' '} */}
              Ticket List
              <Hidden smDown>
                <ArrowDropDownIcon sx={{ mT: 1, fontSize: 40 }} />
              </Hidden>
            </Typography>
          </Grid>
          <Grid item sm={6} sx={{ textAlign: "right", padding: "6px" }}>
            <Button
              sx={{ mt: { xs: 2, md: 0, backgroundColor: '#1AAF54' } }}
              variant="contained"
              onClick={() => AddWorkLog()}
            >
              Add ticket
            </Button>
            <IconButton
              color="primary"
              sx={{ ml: 2, p: 1 }}>
              <MoreVertTwoToneIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" sx={{ paddingLeft: "27px" }}>
                <Checkbox
                  color="primary"
                  checked={selectedAllWorkLog}
                  indeterminate={selectedWorkLog}
                  onChange={handleSelectAllCheckboxWorkLog}
                />
              </TableCell>
              <TableCell >
                Ticket ID
              </TableCell>
              <TableCell >
                Priority
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell >
                Source
              </TableCell>

              <TableCell >
                Subject
              </TableCell>

              <TableCell >
                Company
              </TableCell>
              <TableCell >
                Reported by
              </TableCell>
              <TableCell >
                Handled by
              </TableCell>
              <TableCell >
                Created Date
              </TableCell>
              <TableCell >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {ticketingToolData.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={12} align="center">
                  <Typography variant="h6" gutterBottom>
                    No result found
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {paginatedUserLists.map((tickkettool) => {
                const isUserChecked = selectedUser.includes(tickkettool.id);
                const partyType = OptionUserType?.find((usertype: any) => usertype?.id == tickkettool?.category);
                const date = new Date(tickkettool.reportedAssignedDate);
                const formattedDate = convertToIndianTime(tickkettool.createdAt);
                return (
                  <TableRow
                    hover
                    key={tickkettool.id}
                    selected={isUserChecked}>
                    <TableCell padding="checkbox" sx={{ paddingLeft: "27px" }}>
                      <Checkbox
                        color="primary"
                        checked={isUserChecked}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleSelectDeleteCheckboxWorkLog(event, tickkettool.id)
                        }
                        value={isUserChecked}
                      />
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap>
                        <Tooltip title="Edit Ticket" arrow>
                  <Button onClick={(d: any) => navigateToEditPage(tickkettool.id)} sx={{ margin: 0,p:0,minWidth:0 }} >{tickkettool.id}</Button>
                        </Tooltip>
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap>
                        {tickkettool.priority}
                      </Typography>

                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap>
                        {tickkettool.status}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap>
                        {tickkettool.source}
                      </Typography>
                    </TableCell>


                    <TableCell>
                      <Tooltip title={tickkettool.description}>
                        <Typography variant="body1">
                          {truncateText(tickkettool.description, 25)}
                        </Typography>
                      </Tooltip>
                    </TableCell>


                    <TableCell>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap>
                        {tickkettool?.company}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {tickkettool?.reportedByName || ''}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap>
                        {tickkettool?.handledby}
                      </Typography>
                    </TableCell>


                    <TableCell>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap>
                        {formattedDate}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Tooltip title="View" arrow>
                        <IconButton
                          onClick={(d: any) => handleClickViewWork(tickkettool.id)}
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small">
                          <VisibilityOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Order" arrow>
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                          component={RouterLink}
                          to={`/management/edittickettool/${tickkettool.id}`}
                        >
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {/* This is commanded code is for delete and restore tickets  */}
                      {/* {showActiveTickets ? (
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        onClick={(d: any) => handleDeleteClick(tickkettool.id)}
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small">
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    ) : (
                    <Tooltip title="Restore" arrow>
                      <IconButton
                        onClick={(d: any) => handleTickettoolActive(tickkettool.id)}
                        sx={{
                          '&:hover': { background: theme.colors.success.lighter },
                          color: theme.palette.success.main
                        }}
                        color="inherit"
                        size="small">
                        <SettingsBackupRestoreRoundedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip> 
                    )} */}
                    </TableCell>
                  </TableRow>
                );

              })}
            </TableBody>
          )}
          {isConfirmationOpen && (
            <ConfirmationModal
              open={isConfirmationOpen}
              onClose={handleCancelDelete}
              onConfirm={handleTickettoolDelete}
              content="user" />
          )}
        </Table>
        {showAlert && <AlertComponent alerts={showAlert} message="Deleted" />}
        {showAlertRestore && <AlertComponent alerts={showAlertRestore} message="Restored" />}
      </TableContainer>

      {ticketingToolData.length > 0 && (
        <Box p={2}>
          <TablePagination
            component="div"
            count={filteredUserLists.length}
            onPageChange={handlePageChangeTicketingTool}
            onRowsPerPageChange={handleLimitChangeTicketingTool}
            page={page}
            rowsPerPage={limit}
        rowsPerPageOptions={[ 10, 25, 30]}
          />
        </Box>
      )}
    </Card>
  );
};

UserListTable.propTypes = {
  ticketingToolData: PropTypes.array.isRequired
};

UserListTable.defaultProps = {
  ticketingToolData: []
};

export default UserListTable;
function swal(arg0: { text: string; icon: string; timer: number; }) {
  throw new Error('Function not implemented.');
}

