import PropTypes from 'prop-types';
import { SetStateAction, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import './Alert.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';




function SimpleDialog(props: any) {



  const { onClose, selectedValue, open, message } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}   className="dialog" >
      <div className="top-content">
        <div className="icon">
          <CheckCircleOutlineIcon className="logo" style={{ fontSize: '7vw' }} />
        </div>

        <div className="cart">

          <h2>{message} Successfully.</h2>
        </div>
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,

  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired
};

function AlertComponent(props: any) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = (value: SetStateAction<string>) => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(props.alerts);
    setMessage(props.message);
    const timer = setTimeout(() => {
      setOpen(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [props.alerts, props.message]);

  return (
    <>
      <SimpleDialog message={message} open={open} onClose={handleClose} />
    </>
  );
}

export default AlertComponent;
