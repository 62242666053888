import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

function App() {
  const content = useRoutes(router);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(localStorage.getItem('UserDetail')) {
         
     navigate(location.pathname);
    }
  }, [])
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
