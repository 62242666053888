import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Alert,
  DialogActions,
} from '@mui/material';
import './deletepopup.css';

const ConfirmationModal = ({ open, onClose, onConfirm, content }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle textAlign={'center'} fontSize={'20px'}> Delete {content} confirmation</DialogTitle>
      <DialogContent className='pop-up'>
        <Alert severity="warning" color="error">
          Are you sure you want to delete this {content}?
        </Alert>
        <Typography className='title' textAlign={'center'} fontSize={'18px'} marginTop={'24px'}>
          The {content} will be deleted, continue?
        </Typography>
        <DialogActions className='btn'>
        <Button
          variant="contained"
          className='cancel-btn'
          style={{ marginTop: '20px', marginRight: '4em',width:'12em' , backgroundColor: 'rgb(38, 83, 156)'}}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          className='delete-btn'
          style={{ marginTop: '20px', marginRight: '3em',width:'12em',backgroundColor: ' rgb(240, 20, 20)', color: '#FFFFFF' }}
          onClick={onConfirm}
        >
          Delete
        </Button>
        </DialogActions>
        </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
