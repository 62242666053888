import { useEffect, useRef, useState } from 'react';

import { NavLink, useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import { BaseAPI } from 'src/providers/base-api';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        text-transform: capitalize
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const navigate = useNavigate();
  const user = {
    name: 'Catherine Pike',
    avatar: '/static/images/avatars/user.png',
    jobtitle: 'Project Manager'
  };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<any>();
  const [OptionUserType, setOptionUserType] = useState<any>();
  const [userType, setUserType] = useState<any>(null);
  const [roleName, setRoleName] = useState<any>(null);




  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCodeValueRef();
      await getUserInfo();

    };

    if (!localStorage.getItem('LoginInfo')) {
      navigate('/');
    } else {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (userType) {
      getUserType();
    }
  }, [userType]);

  const getUserInfo = () => {
    const getUser = JSON.parse(localStorage.getItem('UserDetail'));
    const getUserEmail = getUser?.email;
    BaseAPI.getUserDetails((result) => {
      if (result) {
        const users = result.data.filter((employee: any) => employee?.email === getUserEmail);
        let userType = users.map((data: any) => data.party.partyType);
        setUserType(userType);
        setUsers(users);
      }
    });
  };

  const getUserType = async () => {

    try {
      await BaseAPI.getCodeReference((result: any) => {
        const useRef = result.data.codeTables.filter((dataValue: any) => dataValue.typeCode === "user_ref");
        const user = useRef.map((data: any) => data.values);
        const flattenedUser = user.flat();
        const flattenedUserType = flattenedUser.map((data: any) => data);
        const typeUsers = flattenedUserType.map((data: any) => data);
        const userTypeWithId = typeUsers.filter((data: any) => userType.includes(data.id));
        const roleTypeUsername = userTypeWithId.map((data: any) =>
          data.name
        )
        setRoleName(roleTypeUsername)
      });
    } catch (error) {
      console.log(error);
    }
  };





  const getCodeValueRef = async () => {

    try {
      await BaseAPI.getCodeReference(async (result: any) => {
        result.data.codeTables.map((dataValue: any) => {
          switch (dataValue.typeCode) {
            case 'user_ref': {
              setOptionUserType(dataValue.values);
              break;
            }
            default: {
              "No Code Reference Value Added"
              break;
            }
          }
        })

      });
    }

    catch (error) {
      console.log(error);
    }
  };


  const handleLogout = () => {
    localStorage.removeItem('LoginInfo');
    localStorage.removeItem('UserDetail');
    localStorage.removeItem('logemail');
    localStorage.removeItem('UserEmail');
    navigate('/');
  }

  return (
    <>
      {users && users.map((data) => (



        <>
          {/* const partyType = OptionUserType?.find((usertype: any) => usertype.id === data.party.partyType); */}


          <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
            <Avatar variant="rounded" alt={data.party.firstName} src={user.avatar || data?.userProfile} />
            <Hidden mdDown>
              <UserBoxText>
                <UserBoxLabel variant="body1">{data.party.firstName} {data.party.lastName} </UserBoxLabel>
                <UserBoxDescription variant="body2">
                  {/* {partyType?.name} */}
                  {/* {data.party.partyType} */}
                </UserBoxDescription>
              </UserBoxText>
            </Hidden>
            <Hidden smDown>
              <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
            </Hidden>
          </UserBoxButton><Popover
            anchorEl={ref.current}
            sx={{ top: '70px', marginRight: '10px', backdropFilter: 'blur(0px)' }}
            onClose={handleClose}
            open={isOpen}
          // anchorOrigin={{
          //   vertical: 'top',
          //   horizontal: 'right'
          // }}
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'right'
          // }}
          >
            <MenuUserBox sx={{ minWidth: 210 }} display="flex">
              <Avatar variant="rounded" alt={data.party.firstName} src={data.userProfile || user.avatar} />
              <UserBoxText>
                <UserBoxLabel variant="body1">{data.party.firstName} {data.party.lastName}</UserBoxLabel>
                <UserBoxDescription variant="body2">
                  {roleName}
                </UserBoxDescription>
              </UserBoxText>
            </MenuUserBox>
            <Divider sx={{ mb: 0 }} />
            <List sx={{ p: 1 }} component="nav">
              <ListItem
                button
                to="/dashboard"
                component={NavLink}
              >
                <AccountTreeTwoToneIcon fontSize="small" />
                <ListItemText primary="Change Password" />
              </ListItem>
              {/* <ListItem button to="/management/profile/details" component={NavLink}>
                <AccountBoxTwoToneIcon fontSize="small" />
                <ListItemText primary="My Profile" />
              </ListItem>
              <ListItem button to="/dashboard/messenger" component={NavLink}>
                <InboxTwoToneIcon fontSize="small" />
                <ListItemText primary="Messenger" />
              </ListItem>
              <ListItem
                button
                to="/management/profile/settings"
                component={NavLink}
              >
                <AccountTreeTwoToneIcon fontSize="small" />
                <ListItemText primary="Account Settings" />
              </ListItem> */}
            </List>
            <Divider />
            <Box sx={{ m: 1 }}>
              <Button color="primary" fullWidth onClick={() => handleLogout()}>
                <LockOpenTwoToneIcon sx={{ mr: 1 }} />
                Sign out
              </Button>
            </Box>
          </Popover></>

      ))
      }
    </>
  );
}

export default HeaderUserbox;
