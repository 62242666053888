import { Card } from '@mui/material';
import { subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { BaseAPI } from 'src/providers/base-api';
import RecentOrdersTable from './AddUsersField';
import '../AddTickets/AddTicket.css'
import { useParams } from 'react-router';


function AddUsers() {

  const [userData, setUserData] = useState([]);
  const [totalPages, setTotalPage] = useState(1);
  const [searchFilter, setSearchFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [validated, setValidated] = useState(false);
  const [limit, setLimit] = useState(5);
  const [deleteData, setDeleteData] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const params = useParams();
  const userId = params.id ? +params.id : undefined;
  const getUsers = async (filter = null) => {
    try {
      await BaseAPI.userList(filter, (result: any) => {
        if (result.status === 200) {
          setUserData(result.data?.data);
          setTotalPage(result.data?.totalPage);
        } else {
          console.log(result.message);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const filterByCity = async (value) => {
    setSearchFilter(value);
    await getUsers({ city: searchFilter });
  };



  useEffect(() => {
    setIsLoading(true);
    getUsers({ searchFilter, offset });
    setIsLoading(false);
  }, []);

  return (
    <Card>
      <RecentOrdersTable cryptoOrders={userData} />
    </Card>
  );
}

export default AddUsers;
