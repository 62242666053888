import { Helmet } from 'react-helmet-async';

import { useEffect, useState } from 'react';
import { Container, Grid, Card, CardHeader, CardContent, Divider, Typography, Button, MenuItem } from '@mui/material';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { BaseAPI } from 'src/providers/base-api';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import AlertComponent from 'src/User-Details/AlertComponent';
import { SourceList, WorkLog } from 'src/models/CommonInterface';
import MyStatefulEditor from './MyStatefulEditor';
import schema from '../MyDetailsInfo/Validations';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
function EditTicketTool() {

  const parms = useParams();
  const userId: any = parms.id ? parms.id : undefined;
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [statusdata, setStatusdata] = useState<any>();
  const [tickettoollist, settickettoollist] = useState<any>();
  const [sourceData, setSourceData] = useState<SourceList[]>([]);
  const [projectdate, setprojectdate] = useState<any>();
  const [prioritydata, setPrioritydata] = useState<any>();
  const [projectData, setProjectData] = useState([]);
  const [groupdata, setgroupdata] = useState<any>([]);
  const [agentdata, setagentpdata] = useState([]);
  const [userData, setUserData] = useState<any>();
  const [tagData, settagData] = useState<any>();
  const [typedata, settypedata] = useState<any>();
  const [categoryType, setCategoryType] = useState([]);
  const [companyData, setcompanypdata] = useState([]);
  const [prioritylistdata, setPrioritylistdata] = useState<any>();
  const [urgencydata, seturgencydata] = useState<any>();
  const [impactdata, setimpactdata] = useState<any>();
  const [commandInput, setCommandInput] = useState();
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [employeeData, setEmployeeData] = useState([]);
  const [comments, setComment] = useState('');
  const { id } = useParams();
  const [dataId, setDataId] = useState<any>();
  const [commentData, setcommentData] = useState([]);
  const [filterdata, Setfilterdata] = useState([]);
  const [emp, setemp] = useState('');
  const [EmployeeListData, setEmployeeListData] = useState<any>();
  const [reportedBy, setReportedBy] = useState('')

  const username = JSON.parse(localStorage.getItem('UserDetail'));
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(false);

  const [isManualHandledBy, setIsManualHandledBy] = useState(false);
  const [replyClicked, setReplyClicked] = useState(false);

  const onSubmit = async (id: number) => {
    const isValid = await validateForm();
    if (!comments.trim() && tickettoollist.handledby) {
      setIsDescriptionEmpty(true);
      return;
    }
    if (tickettoollist?.workEffortInMinutes === null && tickettoollist?.status === 'Closed') {
      console.log('workEffortInMinutes is empty. Aborting API call.');
      return;
    }
    const toolPayload = {
      priority: tickettoollist?.priority,
      status: tickettoollist?.status,
      source: tickettoollist?.source,
      type: tickettoollist?.type,
      urgency: tickettoollist?.urgency,
      impact: tickettoollist?.impact,
      group: tickettoollist?.group,
      agent: tickettoollist?.agent,
      company: tickettoollist?.company,
      category: tickettoollist?.category,
      handledby: tickettoollist?.handledby,
      handoverto: tickettoollist?.handoverto,
      workEffortInMinutes: tickettoollist?.workEffortInMinutes,
      billableEffortInMinutes: tickettoollist?.billableEffortInMinutes,
      workEffortInHours: tickettoollist?.workEffortInHours,
      billableEffortInHours: tickettoollist?.billableEffortInHours,
      reportedby: tickettoollist?.reportedby,
      tags: tickettoollist?.tags,
      messageId: tickettoollist?.messageId
    };
    const commentPayload = {
      empName: username?.firstName + ' ' + username?.lastName,
      toolId: {id:tickettoollist?.id,
              messageId:tickettoollist?.messageId
      },
      comments: comments,
      to: tickettoollist.ticketTool[0].to || '',
      cc: tickettoollist.ticketTool[0].cc || '',
    };

    try {
      if(comments){
        await BaseAPI.updatetoolkitDetails(id, toolPayload, async (result: any) => {
          if (result.status === 200) {
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              navigate(`/management/tickettoollist`);
            }, 3000);
          } else {
            setAlertMessage('Update Failed!');
          }
        });

        await BaseAPI.addcommand(commentPayload, async (result: any) => {
          if (result.status === 200) {
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              navigate(`/management/tickettoollist`);
            }, 3000);
          } else {
            setAlertMessage('Update Failed!');
          }
        });

      }


    } catch (error) {
      console.log(error);
    }
  };



  const getProjects = async () => {

    try {
      await BaseAPI.allProject((result: any) => {
        if (result.status === 200) {
          result.data?.map((item) => {
            item.value = item.id;
            item.label = item.projectName;
          });

          setProjectData(result.data);

        } else {
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickCancel = () => {
    navigate(`/management/tickettoollist`);
  }


  const handleChange = (event) => {
    const { name, value } = event.target;

    settickettoollist((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === 'handledby' && value === `${username?.firstName} ${username?.lastName}`) {
      setIsManualHandledBy(true);
    }
  };


  const getTicketToolEdit = async () => {
    try {
      const result = await BaseAPI.getTickettoolId(userId);
      if (result && result.comments) {
        setComment(result.comments);
      } else {
      }
      const data = { ...result }
      setDataId(data?.id)
      settickettoollist(data);
      setIsManualHandledBy(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getCommentData = async (id) => {
    try {
      await BaseAPI.ListCommentData(async (result) => {
        const filteredValue = result.data.filter(
          (item) => item?.toolId?.id == Number(userId)
        );
        Setfilterdata(filteredValue)
      });
    } catch (error) {
      console.log(error);
    }
  };


  const validateForm = async () => {
    try {
      await schema.validate(tickettoollist, { abortEarly: false });
      setValidationErrors({});
      return true;
    } catch (error) {
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setValidationErrors(errors);
      return false;
    }
  };


  const getCodeValueRef = async () => {
    try {
      await BaseAPI.getCodeReference(async (result) => {
        result.data.codeTables.map((dataValue: any) => {
          switch (dataValue.typeCode) {
            case 'source_ref': {
              setSourceData(dataValue.values);
              break;
            }
            case 'categorytype_ref': {
              setCategoryType(dataValue.values);
              break;
            }
            case 'status_ref': {
              setStatusdata(dataValue.values);
              break;
            }
            case 'priority_ref': {
              setPrioritydata(dataValue.values);
              break;

            }
            case 'type_ref': {
              settypedata(dataValue.values);
              break;

            }
            case 'group_ref': {
              setgroupdata(dataValue.values);
              break;
            }

            case 'urgency_ref': {
              seturgencydata(dataValue.values);
              break;

            }
            case 'agent_ref': {
              setagentpdata(dataValue.values);
              break;
            }

            case 'impact_ref': {
              setimpactdata(dataValue.values);
              break;
            }
            case 'company_ref': {
              setcompanypdata(dataValue.values);
              break;

            }
            case 'user_ref': {
              setUserData(dataValue.values);
              break;

            }
            case 'tags_ref': {
              settagData(dataValue.values);
              break;

            }

            default: {
              "No Code Reference Value Added"
              break;
            }
          }
        })

      });
    }
    catch (error) {
      console.log(error);
    }
  };

  const getEmployeeData = async () => {
    try {
      await BaseAPI.getPersonalInformation(async (result) => {
        const filteredValue = result?.data.filter((data) => (
          data?.department === "Netops"
        ))
        const username = JSON.parse(localStorage.getItem('UserDetail'));
        setemp(username?.firstName)

        setEmployeeData(filteredValue)

      });
    } catch (error) {
      console.log(error);
    }
  };

  const getEmployeeListData = async () => {
    try {
      await BaseAPI.getPersonalInformation(async (result) => {
        setEmployeeListData(result.data)
      });
    } catch (error) {
      console.log(error);
    }
  };




  const convertToIndianTime = (isoString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'Asia/Kolkata',
      year: 'numeric', // Specify 'numeric' for the full numeric year
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const date = new Date(isoString);
    return new Intl.DateTimeFormat('en-IN', options).format(date);
  };



  const handleComments = (data) => {
    setComment(data)
    if (data.trim()) {
      setIsDescriptionEmpty(false);
    }
  }


  function formatTime(time: any) {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${hours}h ${minutes < 10 ? `0${minutes}` : minutes}m`;
  }

  useEffect(() => {
    const userDetail = JSON.parse(localStorage.getItem('UserDetail'));
    // if (userDetail) {
    //   setUsername(userDetail?.firstName);
    // }
    // Fetch other initial data...
  }, []);

  useEffect(() => {
    settickettoollist(prevState => ({
      ...prevState,
      workEffortInHours: formatTime(tickettoollist?.workEffortInMinutes)
    }));
  }, []);

  useEffect(() => {
    settickettoollist(prevState => ({
      ...prevState,
      billableEffortInHours: formatTime(tickettoollist?.billableEffortInMinutes)
    }));
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      await getTicketToolEdit();
    };

    fetchData();
  }, [dataId]);

  useEffect(() => {
    getCodeValueRef();
    getEmployeeListData()
    getProjects();
    getEmployeeData();
    getCommentData(id);
  }, [id]);

  useEffect(() => {
    if (tickettoollist?.reportedby) {
      const namePattern = /"([^"]+)"\s*<[\w.+-]+@[\w.-]+\.[a-zA-Z]{2,7}>/g;
      const matches = [...tickettoollist.reportedby.matchAll(namePattern)];
      const names = matches.map(match => match[1]);
      const nameString = names.join(', ');
      if(nameString){
        setReportedBy( nameString);
      }
      else if(EmployeeListData?.length && !nameString){
        const onlyEmailReportedBy = EmployeeListData.filter((list) => {
      return(list.email == tickettoollist?.reportedby)
        })
  if(onlyEmailReportedBy.length){
   setReportedBy(onlyEmailReportedBy[0]?.firstName + ' ' + onlyEmailReportedBy[0]?.lastName || tickettoollist?.reportedby );   
  }else{
          setReportedBy(tickettoollist?.reportedby)
        }
      }
    }
  }, [tickettoollist, EmployeeListData]);


  const earliestAppearanceMap = new Map();
  filterdata.forEach((comment) => {
    if (!earliestAppearanceMap.has(comment?.toolId?.id)) {
      earliestAppearanceMap.set(comment?.toolId?.id, comment.createdAt)
    } else {
      const existingDate = earliestAppearanceMap.get(comment?.toolId?.id)
      if (new Date(comment.createdAt) < new Date(existingDate)) {
        earliestAppearanceMap.set(comment?.toolId?.id, comment.createdAt)
      }
    }
  })


  const handleReplyClick = () => {
    setReplyClicked(true);
  };

  const isUserAuthorized =
    `${username?.firstName} ${username?.lastName}` === tickettoollist?.handledby ||
    `${username?.firstName} ${username?.lastName}` === tickettoollist?.reportedby;
  return (
    <>
      <Helmet>
        <title> Ticket Tool</title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" mt={3} spacing={3}>
          <Grid item xs={12} >
            <Card>
              <Typography variant="h3" component="h3" m={3} gutterBottom>
                Edit Ticket
              </Typography>
              <Divider />
              <CardContent>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' }
                  }}
                  noValidate
                  autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} lg={6} >
                        <Grid container direction="row" justifyContent="center" alignItems="center"  >
                          <Grid item sx={{
                            '& .MuiTextField-root': { m: 1, width: "25ch" }
                          }}>

                            <TextField
                              id="standard-select-currency-native"
                              select
                              label="Priority"
                              onChange={handleChange}
                              value={tickettoollist?.priority || ""}
                              name="priority"
                              variant="standard"
                            >
                              {prioritydata?.map((usertype: any) => (
                                <MenuItem key={usertype?.name} value={usertype?.name}>
                                  {usertype?.name}
                                </MenuItem>
                              ))}
                            </TextField>
                            <TextField
                              id="standard-select-currency-native"
                              select
                              label="Status"
                              onChange={handleChange}
                              value={tickettoollist?.status || ""}
                              name="status"
                              aria-readonly
                              variant="standard"
                              disabled={username.firstName + ' ' + username.lastName !== tickettoollist?.handledby} // Use disabled attribute instead of readOnly
                            >
                              {statusdata?.map((usertype: any) => (
                                <MenuItem key={usertype?.name} value={usertype?.name}>
                                  {usertype?.name}
                                </MenuItem>
                              ))}
                            </TextField>

                            <TextField
                              id="standard-select-currency-native"
                              select
                              label="Source"
                              name="source"
                              variant="standard"
                              value={tickettoollist?.source || ""}
                              onChange={handleChange}
                            >
                              {sourceData?.map((source: SourceList) => (
                                <MenuItem key={source.name} value={source?.name}>
                                  {source.name}
                                </MenuItem>
                              ))}
                            </TextField>
                            <TextField
                              disabled
                              id="standard-select-currency-native"
                              label="Reportedby"
                              onChange={handleChange}
                              name="reportedby"
                              variant="standard"
                              value={reportedBy}>

                            </TextField>
                            <TextField
                              id="standard-select-currency-native"
                              select
                              label="Impact"
                              onChange={handleChange}
                              value={tickettoollist?.impact || ""}
                              name="impact"
                              variant="standard">
                              {impactdata?.map((usertype: any) => (
                                <MenuItem key={usertype?.name} value={usertype?.name}>
                                  {usertype?.name}
                                </MenuItem>
                              ))}
                            </TextField>
                            <TextField
                              id="standard-select-currency-native"
                              select
                              label="Group"
                              onChange={handleChange}
                              value={tickettoollist?.group || ""}
                              name="group"
                              variant="standard">
                              {groupdata?.map((usertype: any) => (
                                <MenuItem key={usertype?.name} value={usertype?.name}>
                                  {usertype?.name}
                                </MenuItem>
                              ))}
                            </TextField>

                            <TextField
                              className='inputfiled'
                              autoComplete='off'
                              id="standard-required"
                              label="Work effort(Minutes)*" type="search"
                              onChange={handleChange}

                              value={tickettoollist?.workEffortInMinutes || ""}
                              name="workEffortInMinutes"
                              variant="standard"
                              error={tickettoollist?.status === 'Closed' && !!validationErrors?.workEffortInMinutes}
                              helperText={tickettoollist?.status === 'Closed' && validationErrors?.workEffortInMinutes} />


                            <TextField
                              className='inputfiled'
                              autoComplete='off'
                              id="standard-required"
                              label="Billable effort" type="search"
                              onChange={handleChange}

                              value={tickettoollist?.billableEffortInMinutes || ""}
                              name="billableEffortInMinutes"
                              variant="standard"
                            />

                          </Grid>
                        </Grid>


                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <Grid container direction="row" justifyContent="center" alignItems="center"  >
                          <Grid item sx={{
                            '& .MuiTextField-root': { m: 1, width: "25ch" }
                          }}>
                            <TextField
                              id="standard-select-currency-native"
                              select
                              label="handoverto"
                              onChange={handleChange}
                              name="handoverto"
                              variant="standard"
                              value={tickettoollist?.handoverto || ""}>
                              {employeeData?.map((usertype: any) => (
                                <MenuItem key={usertype?.id} value={usertype?.firstName}>
                                  {usertype?.firstName} {usertype?.lastName}
                                </MenuItem>
                              ))}
                            </TextField>
                            <TextField
                              id="standard-select-currency-native"
                              select
                              label="Company"
                              onChange={handleChange}
                              value={tickettoollist?.company || ""}
                              name="company"
                              variant="standard">
                              {companyData?.map((usertype: any) => (
                                <MenuItem key={usertype?.name} value={usertype?.name}>
                                  {usertype?.name}
                                </MenuItem>
                              ))}
                            </TextField>

                            <TextField
                              id="standard-select-currency-native"
                              select
                              label="Category"
                              onChange={handleChange}
                              value={tickettoollist?.category || ""}
                              name="category"
                              variant="standard">
                              {categoryType?.map((usertype: any) => (
                                <MenuItem key={usertype?.name} value={usertype?.name}>
                                  {usertype?.name}
                                </MenuItem>
                              ))}
                            </TextField>

                            <TextField
                              id="standard-select-currency-native"
                              select
                              label="handledby"
                              onChange={handleChange}
                              name="handledby"
                              variant="standard"
                              value={tickettoollist?.handledby || ""}
                              error={!!validationErrors.handledby}
                              helperText={validationErrors.handledby}
                            >
                              {employeeData?.map((usertype: any) => (
                                <MenuItem key={usertype?.id} value={`${usertype?.firstName} ${usertype?.lastName}`}>
                                  {usertype?.firstName} {usertype?.lastName}
                                </MenuItem>
                              ))}
                            </TextField>
                            <TextField
                              sx={{ display: 'visibilitynone' }} // Corrected sx prop
                              id="standard-select-currency-native"
                              select
                              label="Type"
                              name="type"
                              variant="standard"
                              onChange={handleChange}
                              value={tickettoollist?.type || ""}
                            >
                              {typedata?.map((usertype: any) => (
                                <MenuItem key={usertype?.id} value={usertype?.name}>
                                  {usertype?.name}
                                </MenuItem>
                              ))}
                            </TextField>

                            <TextField id="standard-search" disabled label="Subject" type="search" onChange={handleChange}
                              value={tickettoollist?.description}
                              name="description" InputLabelProps={{
                                shrink: true,

                              }}
                              variant="standard"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider sx={{mt : 2}} />
                  <Grid container direction="row" justifyContent="start" alignItems="center" spacing={3}>
                    <Grid item xs={12} lg={6} xl={6} justifyContent="start" alignItems="center" >
                      {isUserAuthorized && (
                        <>
                          {(isManualHandledBy || replyClicked) ? (
                            <>
                              <h2>Description</h2>
                              <div style={{ border: isDescriptionEmpty ? '2px solid red' : 'none' }}>
                                <MyStatefulEditor dataComments={handleComments} />
                              </div>
                            </>
                          ) : (
                            <Grid sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                              <Button variant="outlined" onClick={handleReplyClick}>
                                <TurnLeftIcon /> Reply
                              </Button>
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container direction="row" justifyContent="start" alignItems="center" spacing={3}>
                    <Grid item xs={12} lg={12} xl={12} justifyContent="start" alignItems="center">
                      <div className="previousThreads">
                        <div className="userDetailsContainer">
                          {filterdata.map((comment, index) => (
                            <Card
                              key={index}
                              sx={{
                                p: 2, my: 2,
                                background: comment?.createdAt === earliestAppearanceMap.get(comment?.toolId?.id) ? '#caf0f8' : ''
                              }}
                              variant="outlined">
                              <div>
                                <br />
                                <b>{comment?.empName}</b>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                </Box>

                                <p>Cc : {comment?.cc ? comment?.cc : ''}</p>

                                <b
                                  dangerouslySetInnerHTML={{
                                    __html: comment?.comments.replace(/\n/g, '<br />')
                                  }}>
                                </b>
                                <br />
                                &nbsp; &nbsp; &nbsp; {comment.text}
                                <br />
                                <i>User updated time: {convertToIndianTime(comment.updatedAt)}</i>
                              </div>
                            </Card>
                          ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                    <Button sx={{ margin: 1 }} variant="contained" color="primary"
                      onClick={() => onSubmit(tickettoollist.id)}>Submit</Button>
                    <Button variant="outlined" sx={{ margin: 1 }} onClick={handleClickCancel}>Cancel</Button>
                  </Grid>
                </Box>
              </CardContent>
              {showAlert && <AlertComponent alerts={showAlert} message="Update" />}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>

  );
}

export default EditTicketTool;

