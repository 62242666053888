import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
import RecentOrders from './ViewTicket';

// import RecentOrders from './RecentOrders';

function ViewTickets() {
  return (
    <>
      <Helmet>
        <title>ViewTickets</title>
      </Helmet>
      <Grid container direction="row" justifyContent="center" sx={{py:"12px",px:"30px" ,background: "rgba(255, 255, 255, 0.5)",  boxShadow:" 0px 2px 4px -3px rgba(34, 51, 84, 0.1), 0px 5px 12px -4px rgba(34, 51, 84, 0.05)",    marginBottom: "36px"}}>
        <PageHeader />
        </Grid>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <RecentOrders />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ViewTickets;
