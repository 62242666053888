import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Divider, Typography, Button, MenuItem, Autocomplete } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { BaseAPI } from 'src/providers/base-api';
import AlertComponent from 'src/User-Details/AlertComponent';
import { useNavigate, useParams } from 'react-router';
import schema from '../MyDetailsInfo/Validations';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import 'src/content/applications/Settings/datepage.css';
import { StorageContants, getUserDetailsLocalStorageValue } from 'src/constant/storageConstants';
import { UserInterface } from 'src/constant/common.interface';
import { ImportantDevices } from '@mui/icons-material';
import MyStatefulEditor from './MyStatefulEditor';

function AddTicketTool() {

  const [validationErrors, setValidationErrors] = useState<any>({});
  const [taskData, setTaskData] = useState<any>();
  const [images, setImages] = useState(null);
  const [sourcedata, setSourceData] = useState<any>();
  const [typedata, settypedata] = useState<any>();
  const [prioritydata, setPrioritydata] = useState<any>();
  const [statusdata, setStatusdata] = useState<any>();
  const [categoryType, setCategoryType] = useState([]);
  const [tmfield, setTmField] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const navigate = useNavigate();
  const [groupdata, setgroupdata] = useState<any>([]);
  const [agentdata, setagentpdata] = useState([]);
  const [userData, setUserData] = useState<any>();
  const [EmployeeListData, setEmployeeListData] = useState<any>();


  const [companyData, setcompanypdata] = useState([]);
  const [tickettoollist, settickettooldata] = useState([]);
  const [emp, setemp] = useState('');

  const [isTicketData, setIsTicketData] = useState<boolean>(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState<any>(new Date());
  const [closedHandoverDate, setClosedHandoverDate] = useState<any>();
  const [taskIds, setTaskIds] = useState<any>();
  const userLocal: UserInterface = getUserDetailsLocalStorageValue();
  const [urgencydata, seturgencydata] = useState<any>();
  const [impactdata, setimpactdata] = useState<any>();
  const params = useParams();
  const ticketToolId: any = params.id ? params.id : undefined;
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(false);

  const [comments, setComment] = useState('');
  const [tickettoolData, setWorkLogDate] = useState({
    id: null,
    priority: null,
    status: "",
    source: "",
    type: "",
    urgency: "",
    impact: "",
    group: "",
    agent: "",
    company: "",
    category: "",
    handledby: null,
    handoverto: null,
    reportedby: "",
    comments: "",
    description: "",
    workEffortInMinutes:null,
    workEffortInHours:null,
    billableEffortInMinutes:null,
    billableEffortInHours:null
  })



  const validateForm = async () => {
    try {
      await schema.validate(tickettoolData, { abortEarly: false });
      setValidationErrors({});
      return true;
    } catch (error) {
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setValidationErrors(errors);
      return false;
    }
  };
  const onSubmit = async () => {
    const isValid = await validateForm();
    const username = JSON.parse(localStorage.getItem('UserDetail'));
    if (!comments.trim()) {
      setIsDescriptionEmpty(true);
      return;
    }
    if(tickettoolData.description != '' && comments){
    const toolPayload = {
      priority: tickettoolData?.priority,
      status: tickettoolData?.status,
      source: tickettoolData?.source,
      type: tickettoolData?.type,
      urgency: tickettoolData?.urgency,
      impact: tickettoolData?.impact,
      group: tickettoolData?.group,
      agent: tickettoolData?.agent,
      company: tickettoolData?.company,
      category: tickettoolData?.category,
      handledby: tickettoolData?.handledby,
      handoverto: tickettoolData?.handoverto,
      description: tickettoolData?.description,
      reportedby: tickettoolData?.reportedby,
   
      
      ticketTool: [
        {
          empName: username?.firstName + ' ' + username?.lastName,
          comments: comments
        }
      ]
    };

    console.log(toolPayload,"===?toolPayload")


    try {
      await BaseAPI.tickettoolDetails(toolPayload, async (result) => {
        if (result.status === 200 || result.status === 201) {
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            navigate(`/management/tickettoollist`);
          }, 3000);

        } else {
          setAlertMessage('Update Failed!');
          console.log(result.message);
        }
      });
    }
  catch (e) {
    console.log(e);
  }
}
  }




  const handleClickCancel = () => {
    navigate(`/management/tickettoollist`);
  }

  
  const username = JSON.parse(localStorage.getItem('UserDetail'));

  const getEmployeeData = async () => {
    try {
      await BaseAPI.getPersonalInformation(async (result) => {
        const filteredValue = result?.data.filter((data) => (
          data?.department === "Netops"
        ))
        const username = JSON.parse(localStorage.getItem('UserDetail'));
        setemp(username?.firstName)
        setEmployeeData(filteredValue)
     });
    } catch (error) {
      console.log(error);
    }
  };
  

  const getEmployeeListData = async () => {
    try {
      await BaseAPI.getPersonalInformation(async (result) => {
        setEmployeeListData(result.data)
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getTicketToolList = async (filter = null) => {
    try {
      await BaseAPI.tickettoolList(filter, (result: any) => {

        if (result.status === 200) {
          settickettooldata(result.data);
        } else {
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
    setWorkLogDate((prevalue) => {
      return {
        ...prevalue,
        [name]: value,

      };
    });
  };





  const getCodeValueRef = async () => {
    try {
      await BaseAPI.getCodeReference(async (result) => {
        result.data.codeTables.map((dataValue: any) => {
          switch (dataValue.typeCode) {
            case 'source_ref': {
              setSourceData(dataValue.values);
              break;
            }
            case 'categorytype_ref': {
              setCategoryType(dataValue.values);
              break;
            }
            case 'status_ref': {
              setStatusdata(dataValue.values);
              break;
            }
            case 'priority_ref': {
              setPrioritydata(dataValue.values);
              break;

            }
            case 'type_ref': {
              settypedata(dataValue.values);
              break;

            }


            case 'urgency_ref': {
              seturgencydata(dataValue.values);
              break;

            }
            case 'group_ref': {
              setgroupdata(dataValue.values);
              break;
            }

            case 'agent_ref': {
              setagentpdata(dataValue.values);
              break;
            }

            case 'company_ref': {
              setcompanypdata(dataValue.values);
              break;

            }
            case 'user_ref': {
              setUserData(dataValue.values);
              break;

            }
       
            case 'impact_ref': {
              setimpactdata(dataValue.values);
              break;

            }

            default: {
              "No Code Reference Value Added"
              break;
            }
          }
        })

      });
    }
    catch (error) {
      console.log(error);
    }
  };

  const handleComment = (data) => {
    setComment(data)
    if (data.trim()) {
      setIsDescriptionEmpty(false);
    }
  }



  useEffect(() => {
    getTicketToolList()
    getCodeValueRef();
    getEmployeeData();
    getEmployeeListData();
    setWorkLogDate((prevalue) => {
      return {
        ...prevalue,
        owner: userLocal?.firstName,
      };
    })

  }, []);




  return (
    <>
      <Helmet>
        <title> Ticket tool list</title>
      </Helmet>

      <Container maxWidth="xl">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" mt={2} spacing={3}>
          <Grid item xs={12}>
            <Card >
              <Typography variant="h3" component="h3" m={3} gutterBottom>
                Add Ticket
              </Typography>
              <Divider />
              <CardContent>
                <Box
                  component="form"
                  noValidate sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' }
                  }}
                  autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6} >
                      <Grid container direction="row" justifyContent="center" alignItems="center"  >
                        <Grid item sx={{
                          '& .MuiTextField-root': { m: 1, width: "25ch" }
                        }}>

                          <TextField
                            id="standard-select-currency-native"
                            select
                            label="Priority"
                            onChange={handleChange}
                            value={tickettoolData.priority}
                            name="priority"
                            variant="standard"
                            error={!!validationErrors.priority}
                            helperText={validationErrors?.priority}>
                            {prioritydata?.map((usertype: any) => (
                              <MenuItem key={usertype?.name} value={usertype?.name}>
                                {usertype?.name}
                              </MenuItem>
                            ))}
                          </TextField>

                          <TextField
                            className='inputfiled' id="standard-select-currency-native" select label="Status"
                            onChange={handleChange} value={tickettoolData.status} name="status" variant="standard"
                          >
                            {statusdata?.map((usertype: any) => (
                              <MenuItem key={usertype?.id} value={usertype?.name}>
                                {usertype?.name}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            id="standard-select-currency-native"
                            select
                            label="Reportedby"
                            onChange={handleChange}
                            name="reportedby"
                            variant="standard"
                            value={tickettoolData.reportedby}

                            error={!!validationErrors.reportedby}
                            helperText={validationErrors?.reportedby}>
                            {EmployeeListData?.map((usertype: any) => (
                              <MenuItem key={usertype?.id} value={usertype?.email}>
                                {usertype?.firstName}  {usertype?.lastName}
                              </MenuItem>
                            ))}
                          </TextField>

                          <TextField
                            id="standard-select-currency-native"
                            select
                            label="Impact"
                            name='impact'
                            value={tickettoolData.impact}
                            onChange={handleChange}
                            variant="standard"
                            error={!!validationErrors.impact}
                            helperText={validationErrors?.impact}>
                            {impactdata?.map((usertype: any) => (
                              <MenuItem key={usertype?.id} value={usertype?.name} >
                                {usertype?.name}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            id="standard-select-currency-native"
                            select
                            label="Company"
                            onChange={handleChange}
                            value={tickettoolData.company}
                            name="company"
                            variant="standard"
                            error={!!validationErrors.company}
                            helperText={validationErrors?.company}>
                            {companyData?.map((usertype: any) => (
                              <MenuItem key={usertype?.id} value={usertype?.name}>
                                {usertype?.name}
                              </MenuItem>
                            ))}
                          </TextField>

                          <TextField
                            id="standard-category"
                            select
                            label="Category"
                            onChange={handleChange}
                            value={tickettoolData.category}
                            name="category"
                            variant="standard">
                            {categoryType?.map((usertype: any) => (
                              <MenuItem key={usertype?.id} value={usertype?.name}>
                                {usertype?.name}
                              </MenuItem>
                            ))}
                          </TextField>

                          
                          </Grid>
                        <>
                        </>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Grid container direction="row" justifyContent="center" alignItems="center"  >
                        <Grid item sx={{
                          '& .MuiTextField-root': { m: 1, width: "25ch" }
                        }}>
                          <TextField
                            id="standard-select-currency-native"
                            select
                            label="Source"
                            name='source'
                            value={tickettoolData.source}
                            onChange={handleChange}
                            variant="standard">
                            {sourcedata?.map((usertype: any) => (
                              <MenuItem key={usertype?.id} value={usertype?.name} >
                                {usertype?.name}
                              </MenuItem>
                            ))}
                          </TextField>


                          <TextField
                            id="standard-select-currency-native"
                            select
                            label="Type"
                            name='type'
                            value={tickettoolData?.type}
                            onChange={handleChange}
                            variant="standard"
                            error={!!validationErrors.type}
                            helperText={validationErrors?.type}>
                            {typedata?.map((usertype: any) => (
                              <MenuItem key={usertype?.id} value={usertype?.name} >
                                {usertype?.name}
                              </MenuItem>
                            ))}
                          </TextField>

                          <TextField
                            id="standard-select-currency-native"
                            select
                            label="Group"
                            onChange={handleChange}
                            value={tickettoolData.group}
                            name="group"
                            variant="standard"
                          >
                            {groupdata?.map((usertype: any) => (
                              <MenuItem key={usertype?.id} value={usertype?.name}>
                                {usertype?.name}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            id="standard-select-currency-native"
                            select
                            label="handledby"
                            onChange={handleChange}
                            name="handledby"
                            variant="standard"
                            value={tickettoolData.handledby}>
                            {employeeData?.map((usertype: any) => (
                              <MenuItem key={usertype?.id} value={`${usertype?.firstName} ${usertype?.lastName}`}>
                                {usertype?.firstName} {usertype?.lastName}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            id="standard-select-currency-native"
                            select
                            label="handoverto"
                            onChange={handleChange}
                            name="handoverto"
                            variant="standard"
                            value={tickettoolData.handoverto}

                            error={!!validationErrors.handoverto}
                            helperText={validationErrors?.handoverto}>
                            {employeeData?.map((usertype: any) => (
                              <MenuItem key={usertype?.id} value={usertype?.firstName}>
                                {usertype?.firstName}  {usertype?.lastName}
                              </MenuItem>
                            ))}
                          </TextField>

                          <TextField
                            required
                            className='inputfiled'
                            id="standard-required"
                            label="Subject"
                            variant="standard"
                            value={tickettoolData.description}
                            name="subject"
                            onChange={handleChange}
                            error={!!validationErrors.subject}
                            helperText={validationErrors.subject}
                          />


                        </Grid>

                        {/* <Grid item xs={4}>
              <TextField
                className='inputfiled'
                autoComplete='off'
                id="standard-required"
                label="Work effort*" type="search"
                onChange={handleChange}

                value={tickettoolData.workEffortInMinutes}
                name="workEffortInMinutes"
                variant="standard" 
                error={!!validationErrors.workEffortInMinutes}
                helperText={validationErrors?.workEffortInMinutes}/>
            </Grid>


            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                autoComplete='off'
                id="standard-required"
                label="Billable effort" type="search"
                onChange={handleChange}

                value={tickettoolData.billableEffortInMinutes}
                name="billableEffortInMinutes"
                variant="standard" />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                autoComplete='off'
                id="standard-required"
                label="Work effort*" type="search"
                onChange={handleChange}

                value={tickettoolData.workEffortInHours}
                name="workEffortInHours"
                variant="standard"   error={!!validationErrors.workEffortInHours}
                helperText={validationErrors?.workEffortInHours}
                />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                autoComplete='off'
                id="standard-required"
                label="Billable effort" type="search"
                onChange={handleChange}

                value={tickettoolData.billableEffortInHours}
                name="billableEffortInHours"
                variant="standard" />
            </Grid> */}

                      </Grid>
                    </Grid>


                  </Grid>
                  <Grid container direction="row" justifyContent="start" alignItems="center" spacing={3}>

                    <Grid item xs={12} lg={6} xl={6} justifyContent="start" alignItems="center">
                      <h2>Description</h2>
                       <div style={{ border: isDescriptionEmpty ? '2px solid red' : 'none' }}>
                      <MyStatefulEditor dataComments={handleComment} />
                      </div>
                    </Grid>

                  </Grid>
                  <Grid xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                    <Button sx={{ margin: 1 }} variant="contained" color="primary"
                      onClick={onSubmit}>Submit</Button>
                    <Button variant="outlined" sx={{ margin: 1 }} onClick={handleClickCancel}>Cancel</Button>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {showAlert && <AlertComponent alerts={showAlert} message="Added" />}
      </Container>

    </>
  );
}

export default AddTicketTool;

