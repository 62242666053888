import { FC, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Box,
  Card,
  MenuItem,
  Typography,
  TextField,
  Grid,
  Button,
  Autocomplete,
  IconButton,
} from '@mui/material';
import { BaseAPI } from 'src/providers/base-api';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ProjectList, RecentOrderStatus } from 'src/models/crypto_order';
import { TicketFormData, TicketMainTask } from 'src/models/CommonInterface';
import React from 'react';
import AlertComponent from 'src/User-Details/AlertComponent';
import schema from '../MyDetailsInfo/Validations';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Assuming you're using Material-UI icons

interface RecentOrdersTableProps {
  className?: string;
  addTickets: ProjectList[];
}

const applyPagination = (
  cryptoOrders: ProjectList[],
  page: number,
  limit: number
): ProjectList[] => {
  return cryptoOrders.slice(page * limit, page * limit + limit);
};

const AddTicketField: FC<RecentOrdersTableProps> = ({ addTickets }) => {
  const getUser = JSON.parse(localStorage.getItem('UserDetail'));
  const getUserEmail = getUser;
  const getUserName = getUser?.firstName;
  const [selectedCryptoOrders, setSelectedCryptoOrders] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedCryptoOrders.length > 0;
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [userType, setUserType] = useState();
  const [assignby, assignbysetvalue] = useState();
  const [userData, setUserData] = useState([]);
  const fileInputRef = useRef(null);
  const [images, setImages] = useState(null);
  const params = useParams();
  const ticketId: any = params.id ? params.id : undefined;
  const [validated, setValidated] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [Userinfo, setUserinfo] = useState<any>();
  const [projectName, setProjectName] = useState<any>([]);
  const location = useLocation();
  const parent = JSON.parse(localStorage.getItem('ticketDetails'));
  const ticketFlag = JSON.parse(localStorage.getItem('ticketflag'));
  const [form, setForm] = useState<TicketFormData>({
    

    ticketName: "",
    description: "",
    documentation: "",
    project: {},
    user: [],
    status: "open",
    assignedBy: getUserName,
    commands: "",
    priority: {},
    createuser: {},
    estimationTime: "",
    handoverReason: "",
    handoverTo: "",
    extraTime: "",
    workEffortInMinutes: "",
    billableEffortInMinutes: "",
    workEffortInHours: "",
    billableEffortInHours: "",


  });

  const [forms, setForms] = useState<TicketMainTask>({
    issueType: {},
    ticketName: "",

  });

  function onFileBrowse() {
    fileInputRef.current.click();
  }

  const onChangeFile = ({ target }) => {
    const { files, name, value } = target as any;
    const fileName = files[0].name;
    setForm((prevForm) => ({ ...prevForm, [name]: fileName }));
    handleChange('documentation', fileName);
    setImages(files);
  };


  const handlepriority = (event: any) => {
    const selectedPriorityId = event.target.value;
    setForm({
      ...form,
      priority: selectedPriorityId,

    });

  };

  const handleIssueTypes = (event: any) => {
    const selectedPriorityId = event.target.value;
    setForms({
      ...forms,
      issueType: selectedPriorityId,
    });

  };
  const status = [
    {
      value: 'open',
      label: 'open'
    },
    {
      value: 'Closed',
      label: 'Closed'
    },
    {
      value: 'In-progress',
      label: 'In-progress'
    },
    {
      value: 'Review',
      label: 'Review'
    },
    {
      value: 'Completed',
      label: 'Completed'
    }
  ];
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const [hasValue, setHasValue] = React.useState(false);
  const [focus, setFocused] = React.useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState<any>({
    ticketName: "",
    description: "",
    documendation: "",
    project: {},
    user: [],
    status: "",
    commands: "",
    handoverReason: "",
    handoverTo: "",

  });
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const validateForm = async () => {
    try {
      await schema.validate(form, { abortEarly: false });
      setValidationErrors({});
      return true;
    } catch (error) {
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setValidationErrors(errors);
      return false;
    }
  };
  const handleUpdate = async (event: any) => {
    const isValid = await validateForm();
    event.preventDefault();
    event.stopPropagation();
    const newErrors = validateForm();
    setErrors(newErrors);
    try {
      const formdata = new FormData();
      if (images?.length) {
        formdata.append("file", images?.[0]);
      }
      formdata.append(
        "ticket",
        JSON.stringify({
          ticketName: form.ticketName,
          description: form.description,
          status: form.status,
          assignedBy: form.assignedBy,
          project: { id: form?.project?.id },
          priority: form?.priority,
          issueType: forms.issueType,
          estimationTime: form.estimationTime,
          extraTime: form.extraTime,
          handoverReason: form.handoverReason,
          handoverTo: form.handoverTo
        })
      );
      formdata.append(
        "user",
        JSON.stringify(form.user?.map((userItem) => userItem.value))
      );

      await BaseAPI.updateTicketbyAdmin(formdata, ticketId, (result: any) => {

        setErrorMessage(result?.response?.data?.errors?.username);

        if (result.status === 200 || result.status === 201) {
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            navigate(`/management/tickets`);
          }, 3000);

        } else {

          alert(result?.response?.data?.message)
        }
      });
    } catch (error) {
      setErrorMessage(error?.response?.data?.errors?.username);
    }
    setValidated(true);
  };
  const handleSubmit = async (event: any) => {
    let isValid = await validateForm();
    event.preventDefault();
    event.stopPropagation();
    const newErrors = validateForm();
    setValidated(true);
    setErrors(newErrors);

    try {

      const formdata = new FormData();


      if (images?.length) {
        formdata.append("file", images?.[0]);

      }
      formdata.append("ticketName", form.ticketName);
      formdata.append("description", form.description);
      formdata.append("status", form.status);
      formdata.append("assignedBy", form.assignedBy);
      formdata.append("estimationTime", form.estimationTime);
      formdata.append("extraTime", form.extraTime);
      formdata.append("handoverReason", form.handoverReason);
      formdata.append("handoverTo", form.handoverTo);
      formdata.append("workEffortInMinutes", form.workEffortInMinutes);
      formdata.append("billableEffortInMinutes", form.billableEffortInMinutes);
      formdata.append("workEffortInHours", form.workEffortInHours);
      formdata.append("billableEffortInHours", form.billableEffortInHours);




      formdata.append("priority", JSON.stringify(form.priority || null));
      formdata.append(
        "user",
        JSON.stringify(form.user?.map((userItem) => userItem.id))
      );

      formdata.append("issueType", JSON.stringify(forms.issueType || null));


      if (parent?.id) {
        formdata.append("parentTicket", JSON.stringify({ id: parent?.id }));
        formdata.append("project", JSON.stringify({ id: parent?.project?.id }));
      }
      else {
        formdata.append("project", JSON.stringify({ id: form.project.id }));
      }


      await BaseAPI.addTicket(formdata, Userinfo, (result: any) => {

        setErrorMessage(result?.response?.data?.errors?.username);

        if (result.status === 200 || result.status === 201) {
          localStorage.removeItem("ticketDetails");
          localStorage.removeItem("ticketflag");


          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            navigate(`/management/tickets`);
          }, 3000);

        }
      });
    } catch (error) {
      setErrorMessage(error?.response?.data?.errors);
    }
    setValidated(true);
  };

  const getProjects = async () => {

    try {
      await BaseAPI.allProject((result: any) => {
        if (result.status === 200) {
          result.data?.map((item) => {
            item.value = item.id;
            item.label = item.projectName;
          });

          setProjectData(result.data);

        } else {
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const getUsers = async () => {
    try {
      await BaseAPI.userList(userData, (result: any) => {


        if (result.status === 200) {
          result.data?.map((item) => {
            item.value = item.id;
            item.label = item.firstName + " " + item.lastName;
          });
          setUserData(result.data);

        } else {
          console.log(result.message);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const [optionProjectType, setOptionProjectType] = useState([]);
  const [categorydata, setCategorydata] = useState<any>()

  const getCodeValueRefProject = async () => {
    try {
      await BaseAPI.getCodeReference(async (result) => {
        result.data.codeTables.map((dataValueProject: any) => {

          switch (dataValueProject.typeCode) {
            case 'priority_ref': {
              setOptionProjectType(dataValueProject.values);
              break;
            }

            case 'category_ref': {
              setCategorydata(dataValueProject.values);
              break;
            }

            default: {
              "No Code Reference Value Added"
              break;
            }
          }
        })

      });
    }
    catch (error) {
      console.log(error);
    }
  };

  const getTickets = async () => {
    try {
      await BaseAPI.tickets(ticketId, (result) => {

        if (result.status === 200) {
          const ticketData = result.data.ticket.map((item) => {

            const updatedItem = {
              ...item,
              value: item.id,
              label: item.firstName,
            };
            return updatedItem;
          });

          setTicketData(ticketData);
        } else {
          console.log(result.message);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getIssueType = async () => {
    try {
      await BaseAPI.tickets(ticketId, (result) => {
        if (result.status === 200) {

          const issueTypeData = result.data.issueType.map((item) => {

            const updatedItem = {
              ...item,
              value: item.id,
              label: item.ticketName,
            };
            return updatedItem;
          });
          setTicketData(issueTypeData);
        } else {
          console.log(result.message);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getUserByProject = async (projectName) => {
    try {
      await BaseAPI.getProjectUserByDetails(projectName, (result) => {
        setSelectedItems(result.data)
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMainTaskSelect = async (e) => {

    setForms((prevState) => ({ ...prevState, ["ticketName"]: e }));


  };

  const onSelect = (e) => {
    setForm({ ...form, ["project"]: e });
  };
  const [selectedIssue, setSelectedIssue] = useState("");

  useEffect(() => {
    getProjects();


    setUserinfo(getUserEmail.partyId)
    setUserinfo(getUserEmail.partyId);
    setUserType(getUser.partyType);
    getCodeValueRefProject();
    getUsers();
    getTickets();
    getIssueType();


    if (ticketId) {
      BaseAPI.getTicketByID(ticketId, (result) => {
        if (result?.status == 200) {
          const data = result?.data?.data;

          data.user = [];
          data?.ticketStatus?.map((item) => {
            data.user.push({ value: item.user.id, firstName: item.user.firstName });

          });

          data.project = {
            value: data.project.id,
            label: data.project.projectName,
          };

          const projectNames = data.project.label;
          setProjectName(projectNames)
          console.log(
            data.documentation.split("/")[4][
            "?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA253VQNHSVTP6AJU7%2F20220929%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220929T070510Z&X-Amz-Expires=7200&X-Amz-Signature=6bac97fe59bf6152f133d3a25116a22f54461418175eda9dbb5c46749e770b2d&X-Amz-SignedHeaders=host"
            ],
            "Documentation"
          );
          const urlObject = new URL(data?.documentation);
          const filename = urlObject.pathname.split('/').pop();
          setForm({
            ticketName: data?.ticketName,
            description: data?.description,
            documentation: filename,
            project: [data?.project],
            assignedBy: data?.assignedBy,
            user: data.user,
            status: data?.status,
            priority: data.priority.id,
            extraTime: data.extraTime,
            estimationTime: data.estimationTime,
            handoverReason: data.handoverReason,
            handoverTo: data.handoverTo,
            workEffortInMinutes: data.workEffortInMinutes,
            billableEffortInMinutes: data.billableEffortInMinutes,
            workEffortInHours: data.workEffortInHours,
            billableEffortInHours: data.billableEffortInHours,

          });
          setForms({
            issueType: data?.issueType.id
          })
        }
      });
    }
  }, [ticketId]);



  const handleChange = (fieldName, value) => {
    if (fieldName == 'project') {
      getUserByProject(value.projectName);
    }

    setForm((prevState) => ({ ...prevState, [fieldName]: value }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: '',
    }));
    if (!!errors[fieldName])
      setErrors({
        ...errors,
        [fieldName]: null,
      });
  };

  const handleChanges = (field, value) => {
    setForm({
      ...form,
      [field]:
        value,
    });
  };

  const assignbyhandleChange = (event) => {
    assignbysetvalue(event.target.value);
  };

  const handleCancelChange = (event) => {
    localStorage.removeItem("ticketDetails");
    localStorage.removeItem("ticketflag");
    navigate(`/management/tickets`);

  }


  function formatTime(time: any) {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${hours}h ${minutes < 10 ? `0${minutes}` : minutes}m`;
  }

  form.workEffortInHours = formatTime(form.workEffortInMinutes);
  form.billableEffortInHours = formatTime(form.billableEffortInMinutes);


  return (

    <Card >

      <Typography variant="h3" component="h3" m={3} gutterBottom>
        {ticketId ? 'Edit Ticket' : ticketFlag === true && parent ? 'Add Child Ticket' : 'Add ticket'}
      </Typography>
      <Divider />

      <Box
        component="form"
        onSubmit={ticketId ? handleUpdate : handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <div className="ticket-fields" >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                required
                id="standard-required"
                label="Ticket Name"
                variant="standard"
                value={form.ticketName}
                name="ticketName"
                error={!!validationErrors.ticketName}
                helperText={validationErrors?.ticketName}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                required
                className='inputfiled'
                id="standard-required"
                label="Description"
                variant="standard"
                value={form.description}
                name="description"
                error={!!validationErrors.description}
                helperText={validationErrors?.description}
                onChange={(e: any) =>
                  handleChange(e.target.name, e.target.value)
                }
              />

            </Grid>
            <Grid item xs={4}>
              <input
                type="file"
                ref={fileInputRef}
                name='documentation'
                style={{ display: 'none' }}
                onChange={onChangeFile}
              />
              <TextField
                style={{ width: '100%', bottom: '10px' }}
                className='document-inputfiled'
                required
                autoComplete="off"
                type='text'
                id="standard-required"
                label="Documentation"
                variant="standard"
                name='documentation'
                onClick={onFileBrowse}
                value={form.documentation || ''}
                error={!!validationErrors.documentation}
                helperText={validationErrors?.documentation}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={onFileBrowse}
                      edge="end"
                    >
                      <InsertDriveFileIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>

            {ticketFlag === true && parent ? "" : <Grid item xs={4}>
              {ticketId ? <TextField
                className='inputfiled'
                id="standard-select-currency"
                select
                label="Project"
                value={projectName}
                onChange={(e) => handleChange('project', e.target.value)}
                variant="standard"
              >
                {projectData.map((option) => (
                  <MenuItem key={option.id} value={option.projectName}>
                    {option.projectName}
                  </MenuItem>
                ))}
              </TextField> :

                <TextField
                  className='inputfiled'
                  id="standard-select-currency"
                  select
                  label="Project"
                  value={form.project.id}
                  onChange={(e) => handleChange('project', e.target.value)}
                  variant="standard"
                >
                  {projectData.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option.projectName}
                    </MenuItem>
                  ))}
                </TextField>
              }
            </Grid>}


            <Grid item xs={4} className='user'>
              <Autocomplete
                className='inputfiled'
                multiple
                id="tags-standard"
                value={form.user}
                options={userData}
                getOptionLabel={(userData) => userData.firstName}
                onChange={(event, newValue) => handleChange('user', newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="assign To" variant="standard" />
                )}
                filterOptions={(options, state) => {
                  const inputValue = state.inputValue?.toLowerCase();
                  return options.filter(
                    (option) =>
                      !form.user.some(
                        (selectedItem) => selectedItem.firstName.toLowerCase() === option.firstName.toLowerCase()
                      ) &&
                      option.firstName.toLowerCase().startsWith(inputValue)
                  );
                }
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                id="standard-select-currency"
                select
                label="Priority"
                value={form.priority}
                name="priority"
                variant="standard"
                onChange={handlepriority}
              >
                {optionProjectType?.map((priority: any, id: number) => (

                  <MenuItem key={id} value={priority?.id}>
                    {priority.name}
                  </MenuItem>
                ))}
              </TextField>

            </Grid>

            <Grid item xs={4}>
              <TextField
                disabled={userType === 1 || userType === 2 ? false : true}
                className='inputfiled'
                required
                id="standard-required"
                label="assigned By"
                variant="standard"
                error={!!validationErrors.assignedBy}
                helperText={validationErrors?.assignedBy}
                value={getUserName}
                name="assignedBy"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                id="standard-select-currency"
                select
                label="Issue type"
                name="issueType"
                value={forms.issueType}
                onChange={handleIssueTypes}
                variant="standard"
              >
                {categorydata?.map((option: any, id: number) => (
                  <MenuItem key={id} value={option?.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                id="standard-select-currency"
                select
                label="Status"
                value={form.status}
                name="status"
                variant="standard"
                required
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              >
                {status.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                autoComplete='off'
                id="standard-required-estimation"
                label="Estimation Time"
                variant="standard"
                value={form.estimationTime}
                {...(userType === 4 && {
                  error: !!validationErrors.estimationTime,
                  helperText: validationErrors?.estimationTime,
                })}
                name="estimationTime"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                autoComplete='off'
                id="standard-required"
                label="Handover Reason" type="search"
                onChange={(e) => handleChange(e.target.name, e.target.value)}

                value={form.handoverReason}
                name="handoverReason"
                variant="standard" />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                autoComplete='off'
                id="standard-required"
                label="Handover to" type="search"
                onChange={(e) => handleChange(e.target.name, e.target.value)}

                value={form.handoverTo}
                name="handoverTo"
                variant="standard" />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                autoComplete='off'
                id="standard-required"
                label="Work effort*" type="search"
                onChange={(e) => handleChange(e.target.name, e.target.value)}

                value={form.workEffortInMinutes}
                name="workEffortInMinutes"
                variant="standard" />
            </Grid>


            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                autoComplete='off'
                id="standard-required"
                label="Billable effort*" type="search"
                onChange={(e) => handleChange(e.target.name, e.target.value)}

                value={form.billableEffortInMinutes}
                name="billableEffortInMinutes"
                variant="standard" />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                autoComplete='off'
                id="standard-required"
                label="Work effort*" type="search"
                onChange={(e) => handleChange(e.target.name, e.target.value)}

                value={form.workEffortInHours}
                name="workEffortInHours"
                variant="standard" />
            </Grid>

            <Grid item xs={4}>
              <TextField
                className='inputfiled'
                autoComplete='off'
                id="standard-required"
                label="Billable effort*" type="search"
                onChange={(e) => handleChange(e.target.name, e.target.value)}

                value={form.billableEffortInHours}
                name="billableEffortInHours"
                variant="standard" />
            </Grid>



            
          </Grid>
          {showAlert && <AlertComponent alerts={showAlert} message={ticketId ? "Update" : "Added"} />}
        </div>
        <div className="Save-button">
          <div>
            <Grid xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <Button variant="outlined" sx={{ margin: 1 }}
                onClick={handleCancelChange}
              >Cancel</Button>
              <Button sx={{ margin: 1 }} variant="contained" color="primary"
                type="submit">
                {ticketId ? "Update" : "Submit"}
              </Button>

            </Grid>

          </div>

        </div>
      </Box>
    </Card>
  );
};



AddTicketField.propTypes = {
  addTickets: PropTypes.array.isRequired
};

AddTicketField.defaultProps = {
  addTickets: []
};


export default AddTicketField;

