import { FC, ChangeEvent, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  Checkbox,
  Button,
  Grid,
  Hidden,
} from '@mui/material';
import {  useNavigate, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import {  RecentOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { BaseAPI } from 'src/providers/base-api';
import { project } from 'src/models/CommonInterface';
import ConfirmationModal from 'src/User-Details/Users/deletepopup';
import BulkActions from './BulkActions';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import AlertComponent from 'src/User-Details/AlertComponent';
import jsPDF from 'jspdf';
interface ProjectProps {
  className?: string;
  projectOrders: project[];
}

interface Filters {
  status?: RecentOrderStatus;
}

const applyFilters = (
  projectOrders: project[],
  filters: Filters
): project[] => {
  return projectOrders.filter((projectOrder) => {
    let matches = true;

    if (filters.status && projectOrder.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (
  projectOrders: project[],
  page: number,
  limit: number
): project[] => {
  return projectOrders.slice(page * limit, page * limit + limit);
};

const ProjectTable: FC<ProjectProps> = ({ projectOrders },props) => {
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState<any | null>(null);
  const [selectedListOrders, setselectedListOrders] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedListOrders?.length > 0;
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState<number>(0);
  const navigate = useNavigate();
  const [limit, setLimit] = useState<number>(5);
  const [showAlert, setShowAlert] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    status: null
  });


  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleDeleteClick = (id: string) => {
    setProjectToDelete(id);
    setConfirmationOpen(true);
  };

  const handleCancelDelete = () => {
    setConfirmationOpen(false);
  };

  const getPaginationList = (paginate: any) => {
    setFilteredData(paginate);
  }  

  const params = useParams();
  const projectId: any = params.id ? params.id : undefined;

  const handleSelectProjectDelete = (
    event: ChangeEvent<HTMLInputElement>,
    deleteId: string
  ): void => {
    if (!selectedListOrders?.includes(deleteId)) {
      setselectedListOrders((prevSelected) => [
        ...prevSelected,
        deleteId
      ]);
    } else {
      setselectedListOrders((prevSelected) =>
        prevSelected.filter((id) => id !== deleteId)
      );
    }
  };

  const handleSelectAllCheckboxProject = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setselectedListOrders(
      event.target.checked
        ? props.projectData?.map((item) => item.id)
        : []
        
    );
  };

  const AddProject = () => {
    navigate("/management/addprojects");
  };

  const deleteProjects = async () => {
    try {
      await BaseAPI.deleteProjects( projectToDelete, (result: any) => {
        if (result.status === 200) {
          setFilteredData((prevData) => prevData.filter((projectinfo) => projectinfo.id !== projectToDelete));
          setConfirmationOpen(false);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            window.location.reload()
          }, 3000);
        } else {
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handleDownloadPdf = async (url: string) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], "document", { type: blob.type });

        if (file.type.startsWith('image/')) {
            // Image handling: directly add the image to the PDF
        } else {
            const pdf = new jsPDF();
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onloadend = () => {
                const text = reader.result as string;
                const padding = 10;
                const lines = pdf.splitTextToSize(text, pdf.internal.pageSize.getWidth() - 2 * padding);
                let yPosition = padding;
                lines.forEach((line) => {
                    if (yPosition > pdf.internal.pageSize.getHeight() - padding) {
                        pdf.addPage();
                        yPosition = padding;
                    }
                    pdf.text(line, padding, yPosition);
                    yPosition += 10; // Adjust as needed for line spacing
                });
                const pdfUrl = pdf.output('bloburl');
                window.open(pdfUrl, '_blank');
            };
        }
    } catch (error) {
        console.error('Error downloading the file:', error);
    }
};

  const filteredProjectList = applyFilters(projectOrders, filters);
  const project = applyPagination(
    filteredProjectList,
    page,
    limit
  );
  const selectedSomeProject =
  selectedListOrders?.length > 0 &&
  selectedListOrders?.length < props.projectData?.length;
  const selectedAllProject =
  selectedListOrders?.length === props.projectData?.length;
  const theme = useTheme();

  return (
    <Card sx={{ paddingTop: '20px' }}>
    {selectedBulkActions && (
      <Box flex={1} p={2}>
        <BulkActions />
      </Box>
    )}
    {!selectedBulkActions && (
      <Grid container sx={{ padding: "0 0 0 0" }}>
        <Grid item sm={6} sx={{ alignItems: "center", display: "flex", padding: "20px" }}>
          <Typography variant="h2" color="text.secondary" style={{ alignItems: "center", display: "flex", whiteSpace: "nowrap" }}>
            Project List<Hidden smDown>
              <ArrowDropDownIcon sx={{ mT: 1, fontSize: 35 }} />
            </Hidden>
          </Typography>
        </Grid>
        <Grid item sm={6} sx={{ textAlign: "right", padding: "6px" }}>
          <Button
            sx={{ mt: { xs: 2, md: 0, backgroundColor: '#1AAF54' } }}
            variant="contained"
            onClick={() => AddProject()}
          >
            Add Project
          </Button>
          <IconButton
            color="primary"
            sx={{ ml: 2, p: 1 }}
          >
            <MoreVertTwoToneIcon />
          </IconButton>
        </Grid>
      </Grid>
    )}
    <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell padding="checkbox" sx={{ paddingLeft: "27px" }}>
                <Checkbox
                  color="primary"
                  checked={selectedAllProject}
                  indeterminate={selectedSomeProject}
                  onChange={handleSelectAllCheckboxProject}
                />               
              </TableCell>
              <TableCell>
             Project ID            
                </TableCell>
              <TableCell>
             Project Name              
                </TableCell>
              <TableCell>
            Description             
                </TableCell>
              <TableCell >
            Documentation            
                </TableCell>
              <TableCell>
             Action             
                </TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {project.map((projectinfo) => {
              const isProjectSelected = selectedListOrders?.includes(
                projectinfo.id
              );
              return (
                <TableRow
                  hover
                  key={projectinfo.id}
                  selected={isProjectSelected}
                >
                   <TableCell padding="checkbox" sx={{ paddingLeft: "27px" }}>
                    <Checkbox
                      color="primary"
                      checked={isProjectSelected}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleSelectProjectDelete(event, projectinfo.id)
                      }
                      value={isProjectSelected}
                    />
                  </TableCell>
                  <TableCell>
                      {projectinfo.id}
                  </TableCell>
                  <TableCell>
                      {projectinfo.projectName}
                  </TableCell>
                  <TableCell >
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                      style={{
                        overflowY: 'auto', 
                        maxWidth: '20em', 
                        textOverflow:'none',
                        whiteSpace: 'break-spaces',
                        wordWrap: 'break-word', 
                        maxHeight: '100px' 
                      }}
                  
                    >
                      {projectinfo.description}
                      </Typography>
                  </TableCell>
                  <TableCell>
                  <Typography
                    variant="body1"
                    color="text.primary"
                    gutterBottom
                    noWrap
                  >
                    <a href="#" onClick={() => handleDownloadPdf(projectinfo.documentation)}>Download PDF</a>
                  </Typography>
                </TableCell>
                  <TableCell >
                    <Tooltip title="Edit Order" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                        component={RouterLink}
                        to={`/management/projects/${projectinfo.id}`}
                      >
                        <EditTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Order" arrow>
                      <IconButton
                       key={projectinfo.id}
                       onClick={() => handleDeleteClick(projectinfo.id)}
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          {isConfirmationOpen && (
            <ConfirmationModal
              open={isConfirmationOpen}
              onClose={handleCancelDelete}
              onConfirm={deleteProjects}
              content="project"/>
          )}
        </Table>
        <div>
        {showAlert && <AlertComponent alerts={showAlert} message={"Deleted" } />}
        </div>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredProjectList?.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

ProjectTable.propTypes = {
  projectOrders: PropTypes.array.isRequired
};

ProjectTable.defaultProps = {
  projectOrders: []
};


export default ProjectTable;

