import { FC, ChangeEvent, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  useTheme,
  Container,
  Grid,
} from '@mui/material';

import { BaseAPI } from 'src/providers/base-api';
import { TicketDetails, Tickets } from 'src/models/CommonInterface';
import { RecentOrderStatus } from 'src/models/crypto_order';
import { Link as RouterLink, useParams } from 'react-router-dom';

interface RecentOrdersTableProps {
  className?: string;
  assignTicket: Tickets[];
}

interface Filters {
  status?: RecentOrderStatus;
}

const applyFilters = (
  assignTicket: Tickets[],
  filters: Filters
): Tickets[] => {
  return assignTicket.filter((cryptoOrder) => {
    let matches = true;
    return matches;
  });
};

const applyPagination = (
  assignTicket: Tickets[],
  page: number,
  limit: number
): Tickets[] => {
  return assignTicket.slice(page * limit, page * limit + limit);
};

const RecentOrdersTable: FC<RecentOrdersTableProps> = ({ assignTicket }) => {
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [ticketDetails, setTicketDetails] = useState<any>();

  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  const params = useParams();
  const ticketId: any = params.id ? params.id : undefined;
  const theme = useTheme();
  useEffect(() => {

    if (ticketId) {
      BaseAPI.getTicketByID(ticketId, (result) => {
        if (result?.status == 200) {
          const data = result?.data?.data;
          console.log(data, "<<data")
          setTicketDetails(data);
        }
      });
    }
  }, [ticketId]);


  return (
    <Card>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h4>Task Id</h4>
            <p>{ticketDetails?.id}</p>
          </Grid>
          <Grid item xs={4}>
            <h4>Task Name</h4>
            <p>{ticketDetails?.ticketName}</p>
          </Grid>

          <Grid item xs={4}>
            <h4>Project Name</h4>
            <p>{ticketDetails?.project?.projectName}</p>
          </Grid>

          <Grid item xs={4}>
            <h4>User Name</h4>
            {ticketDetails?.ticketStatus.map((data, index) => (
              <span key={index}>
                {data.user.firstName}
                {index < ticketDetails.ticketStatus.length - 1 && ", "}
              </span>
            ))}
          </Grid>

          <Grid item xs={4}>
            <h4>Status</h4>
            <p>{ticketDetails?.status}</p>
          </Grid>

          <Grid item xs={4}>
            <h4>Assinged By</h4>
            <p>{ticketDetails?.assignedBy}</p>
          </Grid>

          <Grid item xs={4}>
            <h4>Priority</h4>
            <p>{ticketDetails?.priority?.name}</p>
          </Grid>

          <Grid item xs={4}>
            <h4>Description</h4>
            <p>{ticketDetails?.description}</p>
          </Grid>

          <Grid item xs={4}>
            <h4>Estimation Time</h4>
            <p>{ticketDetails?.estimationTime}</p>
          </Grid>

          <Grid item xs={4}>
            <h4>Hand OverReason</h4>
            <p>{ticketDetails?.handoverReason}</p>
          </Grid>

          <Grid item xs={4}>
            <h4>Hand OverTO</h4>
            <p>{ticketDetails?.handoverTo}</p>
          </Grid>

          <Grid item xs={4}>
            <h4>Work EffortInHours</h4>
            <p>{ticketDetails?.workEffortInHours}</p>
          </Grid>

          <Grid item xs={4}>
            <h4>Billable EffortInHours</h4>
            <p>{ticketDetails?.billableEffortInHours}</p>
          </Grid>
          <Grid item xs={4}>
            <h4>Billable EffortInMinutes</h4>
            <p>{ticketDetails?.workEffortInMinutes}</p>
          </Grid>
          <Grid item xs={4}>
            <h4>Billable EffortInMinutes</h4>
            <p>{ticketDetails?.billableEffortInMinutes}</p>
          </Grid>

        </Grid>

      </Container>
    </Card>
  );
};

RecentOrdersTable.propTypes = {
  assignTicket: PropTypes.array.isRequired
};

RecentOrdersTable.defaultProps = {
  assignTicket: []
};

export default RecentOrdersTable;
