import { FC, ChangeEvent, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell, 
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  Button,
  Grid,
  Hidden,
  Checkbox,
} from '@mui/material';

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import VisibilityIcon from '@mui/icons-material/Visibility'; 
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { BaseAPI } from 'src/providers/base-api';
import { Tickets } from 'src/models/CommonInterface';
import { Link as RouterLink } from 'react-router-dom';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { blue, green, red } from '@mui/material/colors';
import {  useNavigate, useParams } from 'react-router';
import ConfirmationModal from 'src/User-Details/Users/deletepopup';
import BulkActions from './BulkActions';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import AlertComponent from 'src/User-Details/AlertComponent';

interface TicketTableProps {
  className?: string;
  assignTicket: Tickets[];
  user:string
}

const applyFilters = (
  assignTicket: Tickets[],
): Tickets[] => {
  return assignTicket.filter((cryptoOrder) => {
    let matches = true;
    return matches;
  });
};

const applyPagination = (
  assignTicket: Tickets[],
  page: number,
  limit: number
): Tickets[] => {
  return assignTicket.slice(page * limit, page * limit + limit);
};

const TicketTable: FC<TicketTableProps> = ({ assignTicket },props) => {
  const [selectedTicketOrders, setSelectedTicketOrders] = useState<string[]>(
    []
  );
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const selectedBulkActions = selectedTicketOrders?.length > 0;
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [filters, setFilters] = useState({
    status: null
  });
  const params = useParams();
  const ticketId: any = params.id ? params.id : undefined;
  const [filteredData, setFilteredData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [ticketToDelete, setTicketToDelete] = useState<any | null>(null);
  const navigate = useNavigate();

  const handleDeleteClick = (id: string) => {
    setTicketToDelete(id);
    setConfirmationOpen(true);
  };

  const handleCancelDelete = () => {
    setConfirmationOpen(false);
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredTicket = applyFilters(assignTicket);
  const paginatedOrders = applyPagination(
    filteredTicket,
    page,
    limit
  );
  const selectedSomeTicket =
    selectedTicketOrders?.length > 0 &&
    selectedTicketOrders?.length < props.assignTicket?.length;
  const selectedAllTicket =
    selectedTicketOrders?.length === props.assignTicket?.length;
  const theme = useTheme();

  const toggleRowExpansion = (childTicket: string) => {
    setExpandedRows((prevExpanded) => {
      const expanded = new Set(prevExpanded);
      if (expanded.has(childTicket)) {
        expanded.delete(childTicket);
      } else {
        expanded.add(childTicket);
      }
      return expanded;
    });
  };

  const edithandle = () => {
    localStorage.removeItem("ticketDetails");
    localStorage.removeItem("ticketflag");
  }

  const deleteTickets = async () => {
    try {
      await BaseAPI.deleteTickets(ticketToDelete, (result: any) => {
        if (result.status === 200) {
          setFilteredData((prevData) => prevData.filter((ticket) => ticket.id !== ticketToDelete));
          if(result.data.message){
         alert("You have a child ticket so you wont delete this ticket") 
          }
          setConfirmationOpen(false);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            window.location.reload()
          }, 3000);
        } else {
        }
      });
    } catch (e) {
      console.log(e,"event");
    }
  };

  const AddTicket = () => {
    navigate("/management/addtickets");
  };

  const handleSelectTicketDelete = (
    event: ChangeEvent<HTMLInputElement>,
    deleteId: string
  ): void => {
    if (!selectedTicketOrders?.includes(deleteId)) {
      setSelectedTicketOrders((prevSelected) => [
        ...prevSelected,
        deleteId
      ]);
    } else {
      setSelectedTicketOrders((prevSelected) =>
        prevSelected.filter((id) => id !== deleteId)
      );
    }
  };

  const handleSelectAllCheckboxTicket = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedTicketOrders(
      event.target.checked
        ? props.cryptoOrders?.map((item) => item.id)
        : []
        
    );
  };


  useEffect(() => {
  }, []);
  
  return (
    <Card>
    {selectedBulkActions && (
      <Box flex={1} p={2}>
        <BulkActions />
      </Box>
    )} 
    {!selectedBulkActions && (
      <Grid container sx={{ padding: "0 0 0 0" }}>
        <Grid item sm={6} sx={{ alignItems: "center", display: "flex", padding: "20px" }}>
          <Typography variant="h2" color="text.secondary" style={{ alignItems: "center", display: "flex", whiteSpace: "nowrap" }}>
            Tickets List<Hidden smDown>
              <ArrowDropDownIcon sx={{ mT: 1, fontSize: 35 }} />
            </Hidden>
          </Typography>
        </Grid>
        <Grid item sm={6} sx={{ textAlign: "right", padding: "6px" }}>
          <Button
            sx={{ mt: { xs: 2, md: 0, backgroundColor: '#1AAF54' } }}
            variant="contained"
            onClick={() => AddTicket()}
          >
            Add Ticket
          </Button>
          <IconButton
            color="primary"
            sx={{ ml: 2, p: 1 }}
          >
            <MoreVertTwoToneIcon />
          </IconButton>
        </Grid>
      </Grid>
    )}
    <Divider />
    <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell padding="checkbox" sx={{ paddingLeft: "27px" }}>
                <Checkbox
                  color="primary"
                  checked={selectedAllTicket}
                  indeterminate={selectedSomeTicket}
                  onChange={handleSelectAllCheckboxTicket}
                />
              </TableCell>
              <TableCell style={{whiteSpace: 'nowrap',}}>
             Ticket ID              
                </TableCell>
              <TableCell>
                Ticket Name
                </TableCell>
              <TableCell>
                Description
                </TableCell>
              <TableCell >
             Documentation
                </TableCell>
              <TableCell>
                Project
                </TableCell>
                <TableCell style={{whiteSpace: 'nowrap',}}>
               Assign to
                </TableCell>
                <TableCell>
               Priority
                </TableCell>
                <TableCell>
               Issuetype             
                </TableCell>
              <TableCell>
                Status
               </TableCell>
               <TableCell style={{whiteSpace: 'nowrap',}}>
                Assigned By
               </TableCell>
               <TableCell style={{whiteSpace: 'nowrap',}}>
                Estimation Time
               </TableCell>
              <TableCell>
                Action
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedOrders.map((ticket, indexIds) => {
              const isTicketSelected = selectedTicketOrders?.includes(
                ticket.id
              );
              const isExpanded = expandedRows.has(ticket.id);

              return (
                <>
                  <TableRow
                    hover
                    key={ticket.id}
                    selected={isTicketSelected}
                  >
                    <TableCell padding="checkbox" sx={{ paddingLeft: "27px" }}>
                      <Checkbox
                        color="primary"
                        checked={isTicketSelected}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleSelectTicketDelete(event, ticket.id)
                        }
                        value={isTicketSelected}
                      />
                    </TableCell>
                    <TableCell>
                        {ticket.id}
                    </TableCell>
                    <TableCell>
                        {ticket.ticketName}
                    </TableCell>

                    <TableCell>
                        {ticket.description}
                    </TableCell>
                    <TableCell>
                        <a href={ticket.documentation}>download PDF</a>
                    </TableCell>
                    <TableCell>
                        {ticket?.project.projectName}
                    </TableCell>
                    <TableCell>
                       {ticket?.ticketStatus?.map((data, index) => (
                        <span key={index}>
                        {data.user?.firstName}
                        {index < ticket.ticketStatus.length - 1 && ", "} 
                      </span>
                    ))}
                    </TableCell>
                    <TableCell>
                        {ticket?.priority?.name}
                    </TableCell>
                    <TableCell>
                        {ticket?.issueType?.name}
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {ticket?.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                        {ticket?.assignedBy}
                    </TableCell>
                    <TableCell>
                        {ticket?.estimationTime}
                    </TableCell>
                    <TableCell className='action' style={{ minWidth:'120px' ,alignItems: 'center'}}>
                      <Tooltip title="View Task" arrow>
                        
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: green
                          }}
                          color="inherit"
                          size="small"
                          component={RouterLink}
                          to={`/management/viewtickets/${ticket.id}`}
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Task" arrow>
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                          component={RouterLink}
                          to={`/management/edittickets/${ticket.id}`}
                          disabled={ticket.status === 'Closed'}
                          onClick={edithandle}
                        >
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete " arrow>
                        <IconButton
                        key={ticket.id}
                        onClick={() => handleDeleteClick(ticket.id)}
                          sx={{
                            '&:hover': { background: theme.colors.error.lighter },
                            color: theme.palette.error.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {ticket.childTickets?.length > 0 && (
                        <Tooltip title="Sub task" arrow>
                          <IconButton
                            aria-label="expand-child-tickets"
                            sx={{ margin: 1 }}
                            size="small"
                            onClick={() => toggleRowExpansion(ticket.id)}
                          >
                            <ArrowDownwardIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>

                  {/* Child row content */}
                  {isExpanded &&  
                    ticket.childTickets.map((childTicket,indexIds) =>(
                         <TableRow className='childtickets'>
                        <TableCell padding="checkbox" sx={{ paddingLeft: "27px" }}>
                          <Checkbox
                            color="primary"
                            checked={isTicketSelected}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              handleSelectTicketDelete(event, childTicket)
                            }
                            value={isTicketSelected}
                          />
                        </TableCell>                    
                        <TableCell>
                            {childTicket.id}
                        </TableCell>
                    <TableCell>
                        {childTicket.ticketName}
                    </TableCell>

                    <TableCell>
                    
                        {childTicket.description}
                    </TableCell>
                    <TableCell>
                        <a href={childTicket.documentation}>download PDF</a>
                    </TableCell>

                    <TableCell>
                        {childTicket.project?.projectName}
                    </TableCell>
                    <TableCell>
                       {childTicket?.ticketStatus?.map((data, index) => (
                        <span key={index}>
                        {data.user?.firstName}
                        {index < childTicket.ticketStatus.length - 1 && ", "} 
                      </span>
                    ))}
                    </TableCell>
                    <TableCell>
                        {childTicket?.priority?.name}
                    </TableCell>
                    <TableCell>
                        {childTicket?.issueType?.name}
                    </TableCell>
                    <TableCell>
                        {childTicket.status}
                    </TableCell>
                    <TableCell>
                        {childTicket.assignedBy}
                    </TableCell>
                    <TableCell>
                        {childTicket?.estimationTime}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="View Task" arrow>
                        
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: green
                          }}
                          color="inherit"
                          size="small"
                          component={RouterLink}
                          to={`/management/viewtickets/${childTicket.id}`}
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit Order" arrow>
                        <IconButton
                          sx={{
                            '&:hover': {
                              background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                          }}
                          color="inherit"
                          size="small"
                          component={RouterLink}
                          to={`/management/edittickets/${childTicket.id}`}
                          disabled={childTicket.status === 'Closed'}
                        >
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete Order" arrow>
                        <IconButton
                        key={ticket.id}
                        onClick={() => handleDeleteClick(ticket.id)}
                          sx={{
                            '&:hover': { background: theme.colors.error.lighter },
                            color: theme.palette.error.main
                          }}
                          color="inherit"
                          size="small"
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    </TableRow>
                   
             ) )}
                </>
              );
            })}
          </TableBody>
          {isConfirmationOpen && (
            <ConfirmationModal
              open={isConfirmationOpen}
              onClose={handleCancelDelete}
              onConfirm={deleteTickets}
              content="Ticket"/>
          )}
        </Table>
        <div>
        {showAlert && <AlertComponent alerts={showAlert} message={"Deleted" } />}
        </div>

      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredTicket.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};
TicketTable.propTypes = {
  assignTicket: PropTypes.array.isRequired
};

TicketTable.defaultProps = {
  assignTicket: []
};

export default TicketTable;
