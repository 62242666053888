import { Typography, Button, Grid } from '@mui/material';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useParams } from 'react-router';

function PageHeader() {
  const user = {
    name: 'Catherine Pike',
    avatar: '/static/images/avatars/1.jpg'
  };

  const params = useParams();
  const userId = params.id ? +params.id : undefined;
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        
        <Typography variant="h3" component="h3" gutterBottom>
        {userId ? (
                   "Edit Users"
                  ) : (
                    "New Users"
                  )}        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
