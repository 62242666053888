import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Divider, Typography, Button, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { BaseAPI } from 'src/providers/base-api';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import AlertComponent from 'src/User-Details/AlertComponent';
import moment from 'moment';
import { SourceList, WorkLog } from 'src/models/CommonInterface';

function ViewTicketTool() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showAlert, setShowAlert] = useState(false);
  const [tickettoollist, settickettoollist] = useState<any>();
  const [formData, setFormData] = useState<any>();
  const [filterdata, Setfilterdata] = useState([]);




  const handleClickCancel = () => {
    navigate(`/management/tickettoollist`);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const parms = useParams();
  const userId: any = parms.id ? parms.id : undefined;
  const getTicketToolView = async () => {
    try {
      const result = await BaseAPI.Edittickettool(userId);
      if (result) {
        settickettoollist(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getCommentData = async (id) => {
    try {
      await BaseAPI.ListCommentData(async (result) => {
        const filteredValue = result.data.filter(

          (item) => item?.toolId?.id == Number(userId)


        );
        Setfilterdata(filteredValue)

      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTicketToolView();
    getCommentData(id);

  }, []);




  return (
    <>
      <Helmet>
        <title> Ticket Tool</title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid container direction="row" justifyContent="center" alignItems="stretch" mt={3} spacing={3}>
          <Grid item xs={12} >
            <Card>
              <Typography variant="h3" component="h3" m={3} gutterBottom>
                View Ticket 
              </Typography>
              <Divider />
              <CardContent>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '25ch' }
                  }}
                  noValidate
                  autoComplete="off">
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item xs={12} lg={6} xl={6} justifyContent="center" alignItems="center">
                      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                        <Grid item >
                          <TextField id="standard-search" label="Priority" type="search" onChange={handleChange}
                            value={tickettoollist?.priority} InputLabelProps={{
                              shrink: true,

                            }}
                            name="priority"
                            variant="standard" />
                          <TextField id="standard-search" label="Status" type="search" onChange={handleChange}
                            value={tickettoollist?.status} InputLabelProps={{
                              shrink: true,

                            }}
                            name="status"
                            variant="standard" />
                          <TextField id="standard-search" label="Source" type="search" onChange={handleChange}
                            value={tickettoollist?.source} InputLabelProps={{
                              shrink: true,

                            }}
                            name="source"
                            variant="standard" />
                          <TextField id="standard-search" label="Urgency" type="search" onChange={handleChange}
                            value={tickettoollist?.urgency} InputLabelProps={{
                              shrink: true,

                            }}
                            name="urgency"
                            variant="standard" />
                          <TextField id="standard-search" label="Impact" type="search" onChange={handleChange}
                            value={tickettoollist?.impact} InputLabelProps={{
                              shrink: true,

                            }}
                            name="impact"
                            variant="standard" />
                          <TextField id="standard-search" label="Group" type="search" onChange={handleChange}
                            value={tickettoollist?.group} InputLabelProps={{
                              shrink: true,

                            }}
                            name="group"
                            variant="standard" />
                               {/* <TextField id="standard-search" label="WorkEffortInHours" type="search" onChange={handleChange}
                            value={tickettoollist?.workEffortInMinutes} InputLabelProps={{
                              shrink: true,

                            }}
                            name="workEffortInHours"
                            variant="standard" /> */}


                               {/* <TextField id="standard-search" label="WorkEffortInMinutes" type="search" onChange={handleChange}
                            value={tickettoollist?.workEffortInMinutes} InputLabelProps={{
                              shrink: true,

                            }}
                            name="workEffortInMinutes"
                            variant="standard" />
                                   <TextField id="standard-search" label="BillableEffortInHours" type="search" onChange={handleChange}
                            value={tickettoollist?.billableEffortInHours} InputLabelProps={{
                              shrink: true,

                            }}
                            name="billableEffortInHours"
                            variant="standard" /> */}


                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={6}>
                      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                        <Grid item >
                          <TextField id="standard-search" label="Type*" type="search" onChange={handleChange}
                            value={tickettoollist?.type} InputLabelProps={{
                              shrink: true,

                            }}
                            name="owner"
                            variant="standard" />
                          <TextField id="standard-search" label="Company" type="search" onChange={handleChange}
                            value={tickettoollist?.company} InputLabelProps={{
                              shrink: true,

                            }}
                            name="company"
                            variant="standard" />
                          <TextField id="standard-search" label="Category" type="search" onChange={handleChange}
                            value={tickettoollist?.category} InputLabelProps={{
                              shrink: true,

                            }}
                            name="category"
                            variant="standard" />
                          <TextField id="standard-search" label="Handled by" type="search" onChange={handleChange}
                            value={tickettoollist?.handledby} InputLabelProps={{
                              shrink: true,

                            }}
                            name="handoverto"
                            variant="standard" />
                               <TextField id="standard-search" label="Handover to" type="search" onChange={handleChange}
                            value={tickettoollist?.handoverto} InputLabelProps={{
                              shrink: true,

                            }}
                            name="handledby"
                            variant="standard" />
                          <TextField id="standard-search" label="Subject" type="search" onChange={handleChange}
                            value={tickettoollist?.description} InputLabelProps={{
                              shrink: true,

                            }}
                            name="description"
                            variant="standard" />
                                 {/* <TextField id="standard-search" label="BillableEffortInMinutes" type="search" onChange={handleChange}
                            value={tickettoollist?.billableEffortInMinutes} InputLabelProps={{
                              shrink: true,

                            }}
                            name="billableEffortInMinutes"
                            variant="standard" /> */}
                        </Grid>

             



                      </Grid>
                      
                    </Grid>
                    
                  </Grid>
                  <Grid container direction="row" justifyContent="start" alignItems="center" spacing={3}>
                    <Grid item xs={12} lg={6} xl={6} justifyContent="start" alignItems="center">
                      <div className="previousThreads">
                        
                        <div className="userDetailsContainer">

                          {filterdata.map((comment, index) => (
                            <span key={index}>
                              <br />
                              <b> {comment?.empName}</b>
                              <br></br>
                              <b>{comment?.comments}</b>
                              <br /> &nbsp; &nbsp; &nbsp;
                              {comment.text}

                            </span>
                          ))}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                    <Button variant="outlined" sx={{ margin: 1 }} onClick={handleClickCancel}>Cancel</Button>
                  </Grid>
                </Box>
              </CardContent>
              {showAlert && <AlertComponent alerts={showAlert} message="Update" />}
            </Card>
          </Grid>
      
        </Grid>
      </Container>
    </>
  );
}

export default ViewTicketTool;
