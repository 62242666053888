import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';

const MyStatefulEditor = ({ initialValue, onChange, dataComments }) => {
  const [value, setValue] = useState(RichTextEditor.createValueFromString(initialValue || '', 'html'));

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const handleChange = (newValue) => {
    const commandText = stripHtmlTags(newValue.toString('html'));
    dataComments(commandText);
    setValue(newValue);
    if (onChange) {
      onChange(commandText);
    }
  };

  useEffect(() => {
    setValue(RichTextEditor.createValueFromString(initialValue || '', 'html'));
  }, [initialValue]);

  return (
    <RichTextEditor
      value={value}
      onChange={handleChange}
    />
  );
};

MyStatefulEditor.propTypes = {
  initialValue: PropTypes?.string,
  onChange: PropTypes.func,
  dataComments: PropTypes.func.isRequired // Make dataComments required
};

export default MyStatefulEditor;
