import { Helmet } from 'react-helmet-async';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
import RecentOrders from './Ticket';

// import RecentOrders from './RecentOrders';

function Tickets() {
  return (
    <>
      <Helmet>
        <title>Tickets</title>
      </Helmet>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <RecentOrders />
          </Grid>
        </Grid>
    </>
  );
}

export default Tickets;
