import Axios, { HttpStatusCode } from "axios";
import { SearchQueryFilters } from "src/models/CommonInterface";
import axiosInstance from "./httpInterceptor";

export class BaseAPI {
  static getAPIBaseURI() {
    const BASE_API_URL = process.env.REACT_APP_API_URL;

    return BASE_API_URL;
  }
  static buildAPIURL(apiPath: string) {
    const baseURL = BaseAPI.getAPIBaseURI();
    let prefix = "";
    if (!apiPath?.startsWith("/")) {
      prefix = "/";
    }
    const apiUrl = `${baseURL}${prefix}${apiPath}`;
    return apiUrl;
  }

  static async login(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("auth/login"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async userDetails(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("auth/register"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async workLogDetails(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/worklog"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async tickettoolDetails(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/tickettool"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }


  // static async tickettoolList(filter: any, callback: any) {
  //   let url = BaseAPI.buildAPIURL(`/tickettool`);
  //   if (filter) {      
  //     url = url + `?`;
  //     for (const property in filter) {
  //       if (filter[property] !== null) {
  //         url = url + `${property}=${filter[property]}&`;
  //       }
  //     }
  //   }
  //   await Axios.get(url, {})
  //     .then(async (response) => {
  //       callback(response);
  //     })
  //     .catch(function (error) {
  //       if (error.response) {
  //         callback({
  //           status: error.response.status,
  //           message: error.response.data?.error
  //             ? error.response.data?.error
  //             : "General server error",
  //           response: error.response,
  //         });
  //       }
  //     });
  // }

  static async tickettoolList(filter: any, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/tickettool"), { params: filter })
      .then(async (response) => {
        callback(response, filter);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          }, filter);
        }
      });
  }

  // static async tickettoolList(callback: any) {
  //   await Axios.get(BaseAPI.buildAPIURL("/tickettool"), {})
  //     .then(async (response) => {
  //       callback(response);
  //     })
  //     .catch(function (error) {
  //       if (error.response) {
  //         callback({
  //           status: error.response.status,
  //           message: error.response.data?.error
  //             ? error.response.data?.error
  //             : "General server error",
  //         });
  //       }
  //     });
  // }


  static async getWorklogDetails(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/worklog"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }



  static async getallbiometricData(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/bio-metric-connection/getAllAttendanceDetails"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }



  static async newRoles(formdata: any, callback: any) {
    await axiosInstance.post(BaseAPI.buildAPIURL('/access-operation'), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getUserRoleDatabyRoleID(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`sub-category/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getRolesByID(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/roles/role/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getRolesIdOperation(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/access-operation/${id}/role/operation`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }


  static async getWorklogDetailsdata(query: any, callback: any) {
    if (!Object.keys(query).length) {
      return;
    }
    let queryParams = "";
    for (const property in query) {
      queryParams = queryParams + `${property}=${query[property]}`;
    }
    let url = `/worklog/list?${queryParams}`;
    await Axios.get(BaseAPI.buildAPIURL(url), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }


  static async getWorklogDetailId(id: string) {
    try {
      const response = await Axios.get(BaseAPI.buildAPIURL(`/worklog/${id}`), {});
      if (response?.status === HttpStatusCode.Ok) {
        return response?.data;
      }
      return false;
    } catch (error) {
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data?.error
            ? error.response.data?.error
            : "General server error",
        };
      } else {
        throw error; // rethrow non-response errors
      }
    }
  }

  static async getTickettoolId(id: string) {
    try {
      const response = await Axios.get(BaseAPI.buildAPIURL(`/tickettool/${id}`), {});
      if (response?.status === HttpStatusCode.Ok) {
        return response?.data;
      }
      return false;
    } catch (error) {
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data?.error
            ? error.response.data?.error
            : "General server error",
        };
      } else {
        throw error; // rethrow non-response errors
      }
    }
  }

  static async Edittickettool(id: string) {
    try {
      const response = await Axios.put(BaseAPI.buildAPIURL(`/tickettool/${id}`), {});
      if (response?.status === HttpStatusCode.Ok) {
        return response?.data;
      }
      return false;
    } catch (error) {
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data?.error
            ? error.response.data?.error
            : "General server error",
        };
      } else {
        throw error; // rethrow non-response errors
      }
    }
  }

  static async getWorklogDetailID(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/worklog/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async ListCommentData(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/ticketcommentstatus"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  

  static async getUserDetails(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/users"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async updateUserDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/users/${id}/update`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async updateUserRoles(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/user-roles/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async updateWorklogDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/worklog/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async updatetoolkitDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/tickettool/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }


  static async addcommand(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL(`/ticketcommentstatus`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error?.response?.data?.message || (error.response.data?.error
              ? error.response.data?.error
              : "General server error" )
          });
        }
      });
  }




  static async deleteuser(id: number, callback: any) {
    await Axios.delete(BaseAPI.buildAPIURL(`/delete/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async deleteEmployee(ids: number[], callback: (response: any) => void) {
    try {
      const promises = ids.map(id =>
        Axios.delete(this.buildAPIURL(`/employee/${id}`))
      );
      const results = await Promise.all(promises);

      callback({ status: 200, message: 'Employees deleted successfully' });
    } catch (error) {
      if (error.response) {
        callback({
          status: error.response.status,
          message: error.response.data?.error || "General server error",
        });
      } else {
        callback({
          status: 500,
          message: "Unknown error",
        });
      }
    }

  }


 
  
  static async deleteWorklog(id: number, callback: any) {
    await Axios.delete(BaseAPI.buildAPIURL(`/worklog/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }


  static async deletetickettool(id: number, callback: any) {
    await Axios.delete(BaseAPI.buildAPIURL(`/tickettool/delete/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async activetickettool(id: number, callback: any) {
    await Axios.delete(BaseAPI.buildAPIURL(`/tickettool/restore/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getUserEditDetails(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/user_details"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getEmployeeNameDetails(callback, filter?) {
    await Axios.get(BaseAPI.buildAPIURL("/employee-details"), { params: filter })
      .then(async (response) => {
        callback(response, filter);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          }, filter);
        }
      });
  }

  static async getEmployeeData(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/employee-details"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }


  static async addRoles(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/user-roles/addRoles"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }


  static async getSubCategory(callback: any) {
    await axiosInstance.get(BaseAPI.buildAPIURL("/sub-category"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getRoles(callback: any) {
    await axiosInstance.get(BaseAPI.buildAPIURL("roles/role-details"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }


  static async getRolesData(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/user-roles"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getPersonalInformationID(id ,callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/employee/${id}`), {})
      .then(async (response) => {

        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  static async getPersonalInformation(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/employee"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async addAttendenceUpload(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/user-data"), formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getExpenseDetailByUserId(userId: any, expenseId: any, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/expenses/${userId}/${expenseId}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async addPersonalInformation(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/employee"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error?.response?.data?.message || (error.response.data?.error
              ? error.response.data?.error
              : "General server error" )
          });
        }
      });
  }
   static async deleteDocument(id: number, callback: any) {
    await Axios.delete(BaseAPI.buildAPIURL(`/document/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async addDocument(id: number,formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL(`document`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error?.response?.data?.message || (error.response.data?.error
              ? error.response.data?.error
              : "General server error" )
          });
        }
      });
  }



  static async updatePersonalInformation(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`employee/${id}/update`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async addEmployeeUpload(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/user-data"), formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  static async getEmployeeDetails(callback: any, filter?) {

    await Axios.get(BaseAPI.buildAPIURL("/user-data"), { params: filter })

      .then(async (response) => {
        callback(response, filter);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          }, filter);
        }
      });
  }
  static async getEmployeeDetails1(callback) {

    await Axios.get(BaseAPI.buildAPIURL("/user-data"))

      .then(async (response) => {
        callback(response,);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          },);
        }
      });
  }

  //Settings 
  static async getSettingsDetails(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/settings"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getDepartmentList(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/department"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async deleteDepartment(id: number, callback: any) {
    await Axios.delete(BaseAPI.buildAPIURL(`/department/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async deleteDesignation(id: number, callback: any) {
    await Axios.delete(BaseAPI.buildAPIURL(`/designation/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  static async getDesignationList(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/designation"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getDesignationByID(id,callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/designation/get/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  static async getDepartmentByID(id,callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/department/get/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async deleteWorkLocation(id: number, callback: any) {
    await Axios.delete(BaseAPI.buildAPIURL(`/work-locations/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  static async getWorkLocationList(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/work-locations"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getWorkLocationByID(id,callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/work-locations/get/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  
  
  static async settingDetails(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/settings"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async settingWorkLocationDetails(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/work-locations"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async updateWorkLocation(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/work-locations/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }
  static async settingDepartmentsDetails(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/department"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async settingDesignationDetails(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/designation"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async updateDesignationDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/designation/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }
  static async updateDepartmentsDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/department/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }


  static async updateSettingsDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/settings/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }


  //Leave request create 
  static async createLeave(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/leaveRequest"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async leaveCreate(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/leave_transaction"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  //get all Leave request Data   
  static async getLeaveData(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/leave_transaction"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getLeaveBalances(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/leave-balance"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  //Leave Request Edit 
  static async updateLeaveRequest(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/leave_transaction/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getByIdLeaveData(id: string) {
    try {
      const response = await Axios.get(BaseAPI.buildAPIURL(`/leave_transaction/${id}`), {});
      if (response?.status === HttpStatusCode.Ok) {
        return response?.data;
      }
      return false;
    } catch (error) {
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data?.error
            ? error.response.data?.error
            : "General server error",
        };
      } else {
        throw error; // rethrow non-response errors
      }
    }
  }


  static async getByIdLeaveList(id: number, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/leave_transaction/${id}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  // GetById Leave Request Data 
  static async getByIdLeaveLists(id: number, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/leave_transaction/${id}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }



  static async getByIdLeaveRequest(query: any, callback: any) {
    if (!Object.keys(query).length) {
      return;
    }
    let queryParams = "";
    for (const property in query) {
      queryParams = queryParams + `${property}=${query[property]}`;
    }
    let url = `/leave_transaction/?${queryParams}`;
    await Axios.get(BaseAPI.buildAPIURL(url), {})
      .then(async (response) => {

        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  //Leave Type create 
  static async createLeavetype(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/leave_type"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }
  //Leave Type get all Data 
  static async getLeaveTypeListData(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/leave_type/all"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }
  //Leave Type Delete

  static async deleteLeaveType(id: number, callback: any) {
    await Axios.delete(BaseAPI.buildAPIURL(`/leave_type/delete/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  //Leave Type Update  
  static async updateLeaveTypeDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/leave_type/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getPartyDetails(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/users"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async submitContactForm(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/contact-us"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }




  static async EmployeeNameData(query, callback: any) {
    if (!Object.keys(query).length) {
      return;
    }
    let queryParams = "";
    for (const property in query) {
      queryParams = queryParams + `${property}=${query[property]}`;
    }
    let url = `/employee-details?${queryParams}`;
    await Axios.get(BaseAPI.buildAPIURL(url), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async UserData(query, callback: any) {
    if (!Object.keys(query).length) {
      return;
    }
    let queryParams = "";
    for (const property in query) {
      queryParams = queryParams + `${property}=${query[property]}`;
    }
    let url = `/user-data?${queryParams}`;
    await Axios.get(BaseAPI.buildAPIURL(url), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }


  static async getUserList(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/users"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getByIdUserList(id: number, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/users/${id}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async updateUserInformation(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`users/${id}/update`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getEmployeeListFilter(callback: any, filters: any) {
    await Axios.get(BaseAPI.buildAPIURL("/users"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }


  static async getEmployeeFilter(callback: any, filter: any) {
    await Axios.get(BaseAPI.buildAPIURL("/users"), { params: filter })
      .then(async (response) => {
        callback(response, filter);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          }, filter);
        }
      });
  }


  static async getEmployeeFilterr(callback: any,) {
    await Axios.get(BaseAPI.buildAPIURL("/users"), { })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getCodeReference(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/code-table"))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getEmployeeRole(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/roles/role-details"))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getRequestLeaveBalances(userId, leaveType, callback) {
    await axiosInstance.get(BaseAPI.buildAPIURL(`/leave_transaction/user/${userId}/leaveType/${leaveType}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getUserLeaveBalancesByLeaveType(userId, leaveType, callback) {
    await axiosInstance.get(BaseAPI.buildAPIURL(`/leave-balance/user/${userId}/leaveType/${leaveType}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getUserLeaveBalances(userId, callback) {
    await axiosInstance.get(BaseAPI.buildAPIURL(`/leave-balance/user/${userId}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getCategoryFind(currentPath: any, callback: any) {
    const currentPathUrl = currentPath.charAt(0).toUpperCase() + currentPath.slice(1);
    await axiosInstance.get(BaseAPI.buildAPIURL(`/sub-category/role/operation?category=${currentPathUrl}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }


  static async AddCodeReference(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/code-table"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async updateCodeReference(formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/code-table`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async forgotPassword(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/auth/forget-password"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async resetPassword(formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL("/auth/reset-password"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  //Taskmodule Api
  static async allProject(callback: any) {

    await Axios.get(BaseAPI.buildAPIURL(`/project/all`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async deleteProjects(id: number, callback: any) {
    await Axios.delete(BaseAPI.buildAPIURL(`/project/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async deleteTickets(id: string, callback: any) {
    await Axios.delete(BaseAPI.buildAPIURL(`/tickets/${id}`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }


  static async ticketList(filter: any, callback: any) {
    let url = BaseAPI.buildAPIURL(`/tickets`);
    if (filter) {
      url = url + `?`;
      for (const property in filter) {
        if (filter[property] !== null) {
          url = url + `${property}=${filter[property]}&`;
        }
      }
    }
    await Axios.get(url, {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async addComment(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/status"), formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }


  static async getCommentList(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/status"), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }


  static async getTicketByID(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/tickets/${id}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  // add user post call
  static async userList(filter: any, callback: any) {
    let url = BaseAPI.buildAPIURL("/users");
    if (filter) {
      url = url + `?`;
      for (const property in filter) {
        if (filter[property] !== null) {
          url = url + `${property}=${filter[property]}&`;
        }
      }
    }
    await Axios.get(url, {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }


  static async addUserList(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL("/users"), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getUserByID(id: number, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/users/${id}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }
  static async updateUserByID(payload: any, id: number, callback: any) {
    await Axios.patch(BaseAPI.buildAPIURL(`/users/${id}/update`), payload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async updateRoles(payload: any, id: number, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`sub-category/roles/${id}`), payload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async addProject(formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL(`/project`), formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getProjectByID(id: number, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/project/${id}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async updateProjectByID(formdata: any, id: number, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/project/${id}`), formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }


  static async updateTicketbyAdmin(formdata: any, id: number, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/tickets/${id}`), formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async updateTicketbyUser(payload: any, id: number, callback: any) {
    await Axios.patch(BaseAPI.buildAPIURL(`/tickets/${id}/user`), payload, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async addTicket(formdata: any, Userinfo, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL(`/tickets`), formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }


  static async allUsers(callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/users/all`), {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async tickets(filter = null, callback: any) {
    let url = BaseAPI.buildAPIURL(`/tickets`);
    if (filter) {
      url = url + `?`;
      for (const property in filter) {
        if (filter[property] !== null) {
          url = url + `${property}=${filter[property]}&`;
        }
      }
    }
    await Axios.get(url, {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }


  //project list Get Api call
  static async projectList(filter: any, callback: any) {
    let url = BaseAPI.buildAPIURL(`/project`);
    if (filter) {
      url = url + `?`;
      for (const property in filter) {
        if (filter[property] !== null) {
          url = url + `${property}=${filter[property]}&`;
        }
      }
    }
    await Axios.get(url, {})
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async addJobDetails(id: number, formdata: any, callback: any) {
    await Axios.post(BaseAPI.buildAPIURL(`/job-details/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getJobDetails(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/job-details/get-one/${id}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getCommAddressDetails(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/address/get/${id}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getProjectDetails(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/project-details/${id}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getProjectUserByDetails(projectName: string, callback: any) {
    
    try {
      
      const response = await Axios.get(BaseAPI.buildAPIURL(`/project-details/user/${projectName}`), {
        
      });
      callback(response);
    } catch (error) {
      
      if (error.response) {
        
        callback({
          status: error.response.status,
          message: error.response.data?.error
            ? error.response.data?.error
            : "General server error",
          response: error.response,
        });
      }

    }
  }

  static async getEducationalDetails(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/educational/${id}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }


  static async getTechnicalSkillsDetails(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/technical-skills/${id}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getProfessionalDetails(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/professional-details/${id}`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async updateJobDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/job-details/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }


  static async updateCommAddressDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/address/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }


  static async updateEducationalDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/educational/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async updateTechnicalSkillsDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/technical-skills/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async updateProfessionalDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/professional-details/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async updateProjectDetails(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/project-details/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async update(id: number, formdata: any, callback: any) {
    await Axios.put(BaseAPI.buildAPIURL(`/project-details/${id}`), formdata, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          });
        }
      });
  }

  static async getJobDesignation(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/job-details/${id}/designation`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getJobDepartment(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/job-details/${id}/department`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getJobPeoplemanager(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/job-details/${id}/peoplemanager`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getJobImmediatesupervisor(id: string, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL(`/job-details/${id}/immediatesupervisor`))
      .then(async (response) => {
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
            response: error.response,
          });
        }
      });
  }

  static async getEmployeeAttendanceData(searchFilters: SearchQueryFilters, callback: any) {
    try {
      const filterAttrs = ['limit', 'offset', 'employeeId','query','employeeName', 'startDate', 'endDate', 'room', 'workType'];
      const params = filterAttrs
        .filter(filterAttr => searchFilters[filterAttr] !== undefined && searchFilters[filterAttr] !== null && searchFilters[filterAttr] !== '')
        .map(filterAttr => {
          const filterValue = searchFilters[filterAttr];
          return `${filterAttr}=${filterValue}`;
        });
      const paramString = params.join('&');
      const response = await Axios.get(BaseAPI.buildAPIURL(`bio-metric-connection?${paramString}`));
      callback(response);
      
    } catch (error) {
      if (error.response) {
        callback({
          status: error.response.status,
          message: error.response.data?.error ? error.response.data?.error : 'General server error'
        });
      }
    }
  }

  static async attendenceUpload(formdata: any, callback: any, state: string) {    
    await Axios.post(BaseAPI.buildAPIURL(`/bio-metric-connection/upload/?state=${state}`), formdata, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(async (response) => {
        console.log("response??/////",response);
        
        callback(response);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error ? error.response.data?.error : 'General server error'
          });
        }
      });
  }

  static async connectToBiometricDevice(state: string, selectedIp: string) {
    const apiUrl = selectedIp
      ? BaseAPI.buildAPIURL(`/bio-metric-connection/attendances?state=${state}&ip=${selectedIp}`)
      : BaseAPI.buildAPIURL(`/bio-metric-connection/attendances?state=${state}`);

    try {
      const response = await Axios.get(apiUrl);
      return response.data;
    } catch (error) {
      console.error('Error connecting to biometric device:', error);
      throw error;
    }
  }


  static async biolastentry(formdata: any, callback: any) {
    try {
      const response = await Axios.post(BaseAPI.buildAPIURL("/biometriclastsyncentry"), formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      if (typeof callback === 'function') {
        callback(response);
      }
    } catch (error) {
      if (error.response) {
        callback({
          status: error.response.status,
          message: error.response.data?.error || "General server error",
          response: error.response,
        });
      }
    }
  }
  


  static async getTicketFilter(filter: any, callback: any) {
    await Axios.get(BaseAPI.buildAPIURL("/tickets/ticket/data"), { params: filter })
      .then(async (response) => {
        callback(response, filter);
      })
      .catch(function (error) {
        if (error.response) {
          callback({
            status: error.response.status,
            message: error.response.data?.error
              ? error.response.data?.error
              : "General server error",
          }, filter);
        }
      });
  }

  static async createUploadData(uploadData, file, currentTab: string, callback: any) {
    uploadData.uploadFolder = currentTab;
    currentTab = currentTab.substring(0, currentTab.length - 1);
    const path = `/asset`;
    return new Promise((resolve, reject) => {
      return Axios.post<any>(BaseAPI.buildAPIURL(`${path}`), uploadData).then(async (result) => {
        return Axios.put(result.data.signedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        }).then(async res => {
          if (res.status) {
            const updatePath = '/upload-signed-url';
            let uploadStatus = await Axios.get(BaseAPI.buildAPIURL(`/asset/${result?.data?.assetId}/upload-signed-url`));
            callback(uploadStatus)
          }
        })
      })
    })
  }
  static async createLogoUploadData(uploadData, file, currentTab: string, callback: any) {
    uploadData.uploadFolder = currentTab;
    currentTab = currentTab.substring(0, currentTab.length - 1);
    const path = `/asset`;
    return new Promise((resolve, reject) => {
      return Axios.post<any>(BaseAPI.buildAPIURL(`${path}`), uploadData).then(async (result) => {
        return Axios.put(result.data.signedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        }).then(async res => {
          if (res.status) {
            const updatePath = '/upload-signed-url';
            let uploadStatus = await Axios.get(BaseAPI.buildAPIURL(`/asset/${result?.data?.assetId}/upload-signed-url`));
            callback(uploadStatus)
          }
        })
      })
    })
  }
}
