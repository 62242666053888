import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
import RecentOrders from './AddTicket';
import { useLocation } from 'react-router';

function AddTicket() {

  const location = useLocation();
  let parent: any = location.state ;

  
  return (
    <>
      <Helmet>
      <title>{parent ? (
                   "Sub Ticket"
                  ) : (
                    "Add Ticket"
                  )}  </title>
      </Helmet>
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12} sx={{mt:3}}>
            <RecentOrders />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default AddTicket;
