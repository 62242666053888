import { Box, Button, Grid, IconButton } from '@mui/material';
import { BaseAPI } from 'src/providers/base-api';
import { useCallback, useEffect, useState } from 'react';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { useNavigate } from 'react-router';
import TickettollTable from './TicketToolTable';
import { CSVLink } from 'react-csv';
import SearchFilters from '../WorkLog/searchComponent';

function TicketTool() {

  const [users, setUsers] = useState([]);
  const [addresssearchData, setAddressSearchData] = useState("");
  const [namesearchData, setNameSearchData] = useState<any>("");
  const [employeeName, setEmployeeName] = useState('');
  const [reportedName, setreportedName] = useState('');
  const [statusName, setstatusName] = useState('')
  const [statusdata, setStatusdata] = useState<any>();
  const [employeeId, setEmployeeId] = useState('');
  const [category, setCategory] = useState("");
  const [taskIDSearchData, setTaskIDSearchData] = useState("");
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [tickettoollist, settickettooldata] = useState([]);
  const [emp, setemp] = useState('');
  const [employeeData, setEmployeeData] = useState([]);
  const [EmployeeListData, setEmployeeListData] = useState<any>();


  const getTicketToolList = async (filter = null) => {
    try {
      const filters: any = {
        handledby: employeeName,
        reportedby: reportedName,
        status:statusName

      }
      await BaseAPI.tickettoolList(filters, (result: any) => {   
        if (result.status === 200) {
       const filterTicket= result.data.filter((item)=>{
            if( item.status == 'Closed' && statusName == 'Closed'){ 
              return item   
            }else if(item.status != 'Closed'){
              return item
            }
          })
          settickettooldata(filterTicket);
        } else {
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const navigate = useNavigate();
  const AddWorkLog = () => {
    navigate("/management/addtickettool");
  };

  const toggleAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };

  const getUsersDetails = async (test?) => {
    try {
  
      await BaseAPI.getEmployeeFilterr((result: any) => {
        if (result.status === 200) {
          setUsers(result.data);
        } else {
          console.log(result.message);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };



  const username = JSON.parse(localStorage.getItem('UserDetail'));


  const getEmployeeData = async () => {
    try {
      await BaseAPI.getPersonalInformation(async (result) => {
        
        const filteredValue = result?.data.filter((data) => (
          
          data?.department === "Netops"
        ))
        setEmployeeData(filteredValue)
      });
    } catch (error) {
      console.log(error);
    }
  };



  const getEmployeeListData = async () => {
    try {
      await BaseAPI.getPersonalInformation(async (result) => {
        setEmployeeListData(result.data)
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCodeValueRef = async () => {
    try {
      await BaseAPI.getCodeReference(async (result) => {
        result.data.codeTables.map((dataValue: any) => {
          switch (dataValue.typeCode) {
          
            case 'status_ref': {
              setStatusdata(dataValue.values);
              break;
            }
            default: {
              "No Code Reference Value Added"
              break;
            }
          }
        })

      });
    }
    catch (error) {
      console.log(error);
    }
  };

  const onClear = useCallback(() => {
    getTicketToolList('');
    setreportedName('');
    setstatusName('');
    setEmployeeId('');
    setEmployeeName(''); 
    setNameSearchData('');
    setAddressSearchData('');
    getEmployeeData();
    getUsersDetails('')
  }, [employeeData]);
  
  useEffect(() => {
    getCodeValueRef();
    getTicketToolList('');
    getEmployeeData();
    getUsersDetails()
    getEmployeeListData();
  }, []);

  const advanceFilter = [
    {
      name: "status",
      placeholder: "Enter Status",
      label: "Status",
    },
    {
      name: "taskID",
      placeholder: "Enter TaskID",
      label: "Task ID",
    },
  ];


  const handledby = employeeData.map((employee) => `${employee.firstName}`);
  const getFilterDataFunction = async () => {
      await getTicketToolList(handledby);
  }

  const reportedby = EmployeeListData?.map((employee) => `${employee?.firstName}`);
  
  const getFilterDataFunction1 = async () => {
      await getTicketToolList(reportedby);
  }
  
  const status = statusdata?.map((status) => `${status?.name}`);
  const getFilterDataFunction2 = async () => {
      await getTicketToolList(status);
  }

  const csvData = tickettoollist.map(item => ({
    
    TICKETID: item.id,
    PRIORITY: item.priority,
    STATUS: item.status,
    SOURCE: item.source,
    SUBJECT:item.description,
    COMPANY: item.company,
    HANDLEDBY: item.handledby,
  }));
  return (
    <Box sx={{ p: '20px' }}>
    <Button sx={{ marginLeft: 'auto', display: 'flex', height: '49px' }}>
      <CSVLink data={csvData} filename={'tickettoolreport_data.csv'}>
        <p style={{ color: '#5569ff' }}>Export to CSV</p>
      </CSVLink>
    </Button>
    <Box>
    <>
    
    <Grid container sx={{ marginTop: '20px', marginLeft:'20px' }}> 
        <Grid item sm={10}>
          <SearchFilters 
            isTicketTool = {true}
            employeeName={employeeName}
            setEmployeeName={setEmployeeName}
            reportedName={reportedName}
            setreportedName={setreportedName}
            statusName ={statusName}
            setstatusName = {setstatusName}
            employeeId={employeeId}
            setEmployeeId={setEmployeeId}
            onClear={onClear}
            advancedSearch={showAdvancedSearch}
            setNameSearchData={setNameSearchData}
            advanceFilter={advanceFilter}
            addresssearchData={addresssearchData}
            category={category}
            setCategory={setCategory}
            setAddressSearchData={setAddressSearchData}
            taskIDSearchData={taskIDSearchData}
            setTaskIDSearchData={setTaskIDSearchData}
            getFilterDataFunction={getFilterDataFunction}
            getFilterDataFunction1={getFilterDataFunction1}
            getFilterDataFunction2={getFilterDataFunction2}

            toggleAdvancedSearch={toggleAdvancedSearch} 
            namesearchData={reportedby}
            employeeNames={handledby} 
            ticket_status = {status}

            stringData="WorklogAdmin" />
        </Grid>
        <Grid item sm={2} sx={{ textAlign: "right", padding: "6px" }}>
   
        </Grid>
      </Grid>
      <TickettollTable ticketingToolData={tickettoollist} />
    </>
    </Box>
    </Box>
  );
}

export default TicketTool;

