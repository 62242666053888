import * as yup from 'yup';

const schema = yup.object().shape({
    fullName: yup.string().required('Full Name is required'),
    email: yup.string().email('Invalid email').required('Email is required').matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email'),
    password: yup
      .string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    employeeId: yup.string().required('Employee ID is required'),
    addressLine: yup.string().required('Address is required'),
    genderId: yup.string().required('Gender is required'),
    firstName: yup.string().required('First Name is required'),
    leaveName:yup.string().required('Leave Name is required'),
    leavePerYear:yup.number().required('Leaves Per Year is required'),
    allocationPattern:yup.string().required('Allocation Pattern is required'),
    userType:yup.string().required('User Type is required'),
    lastName:yup.string().required('Last Name is required'),
    workType:yup.string().required('Work Type is required'),
    contactNumber:yup.string().required('Mobile Number is required'),
    mobileNumber:yup.string().required('Mobile Number is required'),
    roles:yup.string().required('Roles is required'),
    category:yup.number().required('Category  is required'),
    source:yup.number().required('Source is required'),
    reportedAssignedBy:yup.string().required('Assigned By is required'),
    comment:yup.string().required('only handled person to add commend'),
    reportedAssignedDate:yup.string().required('Assigned Date is required'),
    reportedTime:yup.string().required('Reported Time is required'),
    taskId:yup.string().required('Task ID is required'),
    project:yup.string().required('Project is required'),
    priority:yup.string().required('Priority is required'),
    taskAsReported:yup.string().required('Task As Reported is required'),
    actionDescription:yup.string().required('Action Description is required'),
    owner:yup.string().required('Name is required'),
    status:yup.string().required('Status is required'),
    workEffortInMinutes:yup.string().required('Work Effort In Minutes is required'),
    billableEffortInMinutes:yup.number().required('Billable Effort In Minutes is required'),
    ticketName:yup.string().required('Ticket Name is required'),
    ticketDescription:yup.string().required('Description is required'),
    assignedBy:yup.string().required('Assigned By is required'),
    workEffortInHours:yup.string().required('Handled By is required'),
    projectName:yup.string().required('Project Name is required'),
    estimationTime:yup.string().required('Estimation Time is required'),
    extraTime:yup.string().required('Estimation Time  is required'),
    dateOfJoining:yup.string().required('Date of Joining is required'),
    leaveType:yup.number().required('Leave Type is required'),
    startDate:yup.date().required('Start Date is required'),
    endDate:yup.date().required('End Date is required'),
    totalDays:yup.number().required('Total Days is required'),
    comments:yup.string().required('Reason is required'),
    name:yup.string().required('Name is required'),
    description:yup.string().required('Role Description is required'),
    reportedby:yup.string().required('Reported by is required'),
    handledby:yup.string().required('Handled by is required'),
    subject:yup.string().required('Subject is required'),
    typeCode:yup.string().required('Reference is required'),
    dateFormat:yup.string().required('Date Format is required'),
    dateOfBirth:yup.string().required('Date of Birth is required'),
    companyName:yup.string().required('Company Name is required'),
    city:yup.string().required('city is required'),
    worklocationName:yup.string().required('Work location Name is required'),
    gstNumber: yup.string().required('gst Number is required'),
    website:yup.string().required('website is required'),
    cinNumber:yup.string().required('cin Number is required'),
    panNumber:yup.string().required('pan  Number is required'),
    address1:yup.string().required('Address 1  is required'),
    address2:yup.string().required('Address 2  is required'),
    designationName:yup.string().required('Designation Name  is required'),
    departmentName:yup.string().required('Department Name  is required'),
    companyAddress: yup.object().shape({
      doorNo: yup.string().required('Door No is required'),
      country: yup.string().required('Country is required'),
      state: yup.string().required('State is required'),
    }),
    pinCode: yup.string().required('Pin Code is required'),
    companyEmail:yup.string().required('Company Email  is required'),
    workLocation:yup.string().required('Work Location  is required'),
    designation:yup.string().required('Designation  is required'),
    department:yup.string().required('Department  is required'),
    personalEmail:yup.string().required('Personal Email  is required'),
    panNo:yup.string().required('Pan Number  is required'),
    mediaName:yup.string().required('Media Name is required'),
    mediaType:yup.number().required('Media Type is required'),
    fileName:yup.string().required('File Name is required'),
  });
  export default schema;